import {defineStore} from 'pinia'

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        isInstallationsReady: false,

        installationsECDIS: ["-1"],
        installationsDevices: ["-1"],
        ordersByDevices: [{id: 1, orders: 2}]
    }),
    getters: {
        isInstallationsLoaded: (state) => state.isInstallationsReady,
        getShips: (state) => {
            let installations = []
            state.installationsECDIS.forEach(item => {
                installations.push({value: item.deviceId, text: item.primarInfo.shipInfo.name})
            })
            return installations
        },
        getAppTypeByDeviceID: (state) => {
            return (val) => state.installationsECDIS.find((a) => a.deviceId === val)?.applicationId
        },
        getInstallationByDeviceID: (state) => {
            return (val) => state.installationsECDIS.find((a) => a.deviceId === val)
        },
    },
    actions: {
        addInstallations(payload) {
            this.installationsECDIS = payload.ecdisList
            this.installationsDevices = payload.devicesList
            this.isInstallationsReady = true
        },
        addOrders(payload) {
            this.ordersByDevices.push({id: payload.id, orders: payload.orders})
        },
        reset() {
        }
    },
    persist: false
})
