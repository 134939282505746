<script setup>
</script>

<template>
  <b-container>
    <b-overlay :show="preparingCells" rounded="sm" spinner-variant="danger">

<!--      {{momentaryStore.route.chartsbyroute}}-->

      <template #overlay>
        <br><br>
        <div class="text-center">
          <b-icon icon="hourglass" variant="warning" font-scale="2" animation="spin"></b-icon>
          <br>
          <b-badge variant="warning">Please wait...</b-badge>
        </div>
      </template>

      <b-button size="sm" variant="warning" class="mt-2" block @click="order()">Order
      </b-button>

      <b-table striped hover :items="items()" :fields="visible_fields">
        <template #cell(name)="data">
          <span class='smt2s'>{{ getCellName(data.index) }}</span>
        </template>
      </b-table>

    </b-overlay>
  </b-container>
</template>

<script>
import {myAxiosRetry} from "@/services/myAxios";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import router from "@/router";

export default {
  data() {
    return {
      preparingCells: false,
    }
  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore),
    visible_fields() {
      return [
        {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    items() {
      return this.momentaryStore.route.chartsbyroute
    },
    getCellName(index) {
      return this.momentaryStore.route.chartsbyroute[index]
    },
    prepareCells() {
      let points = []

      this.preparingCells = true

      this.routesStore.route.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
      })

      return new Promise(resolve => {
        myAxiosRetry.post("api/v1/mss/maps", points
        ).then(resp => {
          this.momentaryStore.addChartsByRoute(resp.data)

          this.preparingCells = false
          resolve()
        });
      })
    },
    order() {
      router.push({name: "proxy-orders"});
    }
  },
}
</script>

<style scoped>

</style>