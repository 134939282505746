<template>
  <div>
    <q-r-window></q-r-window>
    <man-log></man-log>
    <ecdis-details :selected_row="selected_row"></ecdis-details>
    <add-ecdis :selected_row="selected_row"></add-ecdis>

    <b-toast id="hint001" title="Hint" static no-auto-hide>
      To make an order click the &nbsp;<b-icon icon="basket"></b-icon> &nbsp;icon next to the appropriate ship.
    </b-toast>

    <b-modal id="no-details" title="Warning" v-model="show_no_ship_info_warning" ok-only>
      <p class="my-4">No info for the ship available. Scan QR code from ECDIS to read the ship information</p>
    </b-modal>

    <b-modal ref="report-modal" hide-footer title="Report">
      <b-button block class="mt-2" variant="info" @click="scanQR()">QR Code</b-button>
      <b-button block class="mt-2" variant="info" @click="report()">Report</b-button>
      <b-button block class="mt-2" variant="info" @click="showInfo()">On Map</b-button>
    </b-modal>

    <b-modal ref="edit-modal" hide-footer title="View/Edit ship">
      <b-button block class="mt-2" variant="info" @click="showDevice()">View</b-button>
      <b-button block class="mt-2" variant="info" @click="editDevice()">Edit</b-button>
    </b-modal>

    <b-modal ref="order-modal" hide-footer title="Select a data provider">
      <b-button block class="mt-2" variant="info" @click="dataProviderSelected('primar')">Primar</b-button>
      <b-button block class="mt-2" variant="info" @click="dataProviderSelected('ukho')">UKHO</b-button>
    </b-modal>

    <b-row>
      <b-col class="listsmall">

        <b-form-group v-if="!is_mobile">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="searchFilter"
                debounce="1000"
                type="search"
                placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="light" :disabled="!searchFilter" @click="searchFilter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-table selected-variant="info" id="orders-ecdis-table" sort-by="primarInfo.shipInfo.name" class="mt-2" :busy="is_busy" striped sticky-header selectable select-mode="single" @row-selected="onRowSelected" :filter="searchFilter"
                 :items="visibleRows()" :fields="visible_fields" ref="shipsTable">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;{{$store.state.fickle.requests.current_request}}</strong>
            </div>
          </template>
<!--          <template #cell(view)="row">-->
<!--            <b-button v-if="row.item.applicationId === 11" class="unstyled-button" size="sm" title="Details" @click="showDevice(row.item)">-->
<!--              <b-icon variant="dark" icon="card-text" aria-hidden="true"></b-icon>-->
<!--            </b-button>-->
<!--          </template>-->

<!--          <template #cell(index)="data">-->
<!--            {{ data.index + 1 }}-->
<!--          </template>-->

          <template #cell(order)="row">
            <b-button class="unstyled-button" size="sm" title="Order" @click="showOrderDlg(row)">
              <b-icon variant="dark" icon="basket" aria-hidden="true"></b-icon>
            </b-button>
          </template>

          <template #cell(edit)="row">
            <b-button v-if="row.item.applicationId === 11 || row.item.applicationId === 10" class="unstyled-button" size="sm" title="View/Edit" @click="showViewDlg(row.item)">
              <b-icon variant="dark" icon="gear" aria-hidden="true"></b-icon>
            </b-button>
          </template>
          <template #cell(info)="row">
            <b-button v-if="row.item.applicationId === 11" class="unstyled-button" size="sm" title="Report" @click="showReportDlg(row.item)">
              <b-icon variant="dark" icon="geo-alt" aria-hidden="true"></b-icon>
            </b-button>
          </template>
          <template #cell(applicationId)="data">
            {{ getAppName(data.value) }}
          </template>
          <template #cell(deviceId)="row">
            <span v-if="row.item.applicationId === 11 && row.item.primarInfo">{{ row.item.primarInfo.primarPermit }}</span>
            <span v-else>{{ row.item.deviceId }}</span>
          </template>
          <template #cell(primarInfo.shipInfo.name)="row">
            <span v-if="row.item.applicationId === 0">{{ getName(row.item) }} (mKart)</span>
            <span v-if="row.item.applicationId === 1">{{ getName(row.item) }} (NP)</span>
            <span v-if="row.item.applicationId === 2">{{ getName(row.item) }} (Vega)</span>
            <span v-if="row.item.applicationId === 10">{{ getName(row.item) }} (m-Connect)</span>
            <span v-if="row.item.applicationId === 11">{{ getName(row.item) }}</span>
          </template>
<!--          <template #cell(primarInfo.shipInfo.name)="row">-->
<!--            {{ getName(row.item) }}-->
<!--          </template>-->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {eventBus} from "@/main";
import {getAppName} from "@/utils/utils";
import EcdisDetails from "@/views/ships/EcdisDetails.vue"
import AddEcdis from "@/views/ships/AddEcdis.vue";
import QRWindow from "@/components/qr/QRWindow";
import ManLog from "@/components/qr/ManLog";
import router from "@/router";
import store from "@/store";
import {OrderSource} from "@/utils/config";
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {useUserStore} from "@/stores/user";

export default {
  name: "ShipsLists",
  components: {EcdisDetails, AddEcdis, ManLog, QRWindow},
  data() {
    return {
      windowWidth: window.innerWidth,
      show_no_ship_info_warning: false,
      selected_row: null,
      searchFilter: null,
      ship: {
        order_number: null,
        order_date: null,
        ecdis_name: null,
        ecdis_imo: null,
        device_id: null,
        application_id: null
      },
    }
  },
  // props: ["ship_type"],
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    // if (!this.$store.state.proto.hints.hasOwnProperty('hint001'))
    if (!('hint001' in this.$store.state.proto.hints)) {
      this.$bvToast.show('hint001')
      store.commit("proto/setHint", 'hint001')
    }
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    ...mapStores(useUserStore),
    is_busy() {
      return this.$store.state.proto.installationsECDIS.length > 0 && this.$store.state.proto.installationsECDIS[0] === "-1"
    },
    visible_fields() {

      return [
        // 'index',
        {key: 'selected', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'applicationId', label: 'Platform', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'primarInfo.shipInfo.name', label: 'Ship name', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'deviceId', label: 'ID', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'view', label: 'View', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'order', label: 'Order', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'edit', label: 'Edit', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'info', label: 'Report', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },


  },
  methods: {
    getAppName,
    showDevice(row) {
      console.log(row)
      this.$refs["edit-modal"].hide()
      eventBus.$emit('ecdis-selected', this.selected_row)
      // this.selected_row = row
      this.$root.$emit('bv::toggle::collapse', 'ship-details');

      this.$store.dispatch('fickle/getDeviceOrders',
          {
            device_id: this.selected_row.deviceId,
          })
    },
    editDevice(item) {
      this.$refs["edit-modal"].hide()
      // eventBus.$emit('ecdis-selected', row)
      // this.selected_row = item
      this.$root.$emit('bv::toggle::collapse', 'ship-edit');
      eventBus.$emit('edit-ecdis', this.selected_row)
    },
    showReportDlg(row) {
      this.selected_row = row
      this.$refs['report-modal'].show()
    },
    showViewDlg(row) {
      this.selected_row = row
      this.$refs['edit-modal'].show()
    },
    showOrderDlg(row) {
      // this.selected_row = row

      console.log("-----------")
      console.log(row)
      // console.log(row.item)
      // this.$refs.shipsTable.selectRow(row.index)

      eventBus.$emit('ecdis-selected', row.item)

      this.$parent.$parent.ship.order_number = Math.floor(1000 + Math.random() * 9000)
      this.$parent.$parent.ship.order_date = new Date().toLocaleDateString()
      this.$parent.$parent.ship.ecdis_name = row.item.applicationId === 11 ? row.item.primarInfo.shipInfo.name : row.item.appState.shipInfo.name
      this.$parent.$parent.ship.ecdis_imo = row.item.applicationId === 11 ? row.item.appState.shipInfo.imo : row.item.appState.shipInfo.imo
      this.$parent.$parent.ship.device_id = row.item.deviceId
      this.$parent.$parent.ship.application_id = row.item.applicationId

      this.$refs['order-modal'].show()
    },
    getName(item) {
      // return item.primarInfo.shipInfo.name
      if (item.applicationId === 11 && item.primarInfo)
        return item.primarInfo.shipInfo.name ? item.primarInfo.shipInfo.name : 'NONAME'
      else
        return item.appState.shipInfo.name ? item.appState.shipInfo.name : 'NONAME'
    },
    showInfo() {
      let row = this.selected_row
      // console.log(row.primarInfo.primarPermit)

      this.$refs['report-modal'].hide()

      let logs = this.$store.state.fickle.qr.shipLogs

      let objIndex = logs.findIndex((obj => obj.permit === row.primarInfo.primarPermit));
      console.log(objIndex)

      if (objIndex === -1) {
        this.selected_row = row
        this.show_no_ship_info_warning = true
      } else {
        store.commit("fickle/setSelectedPermit", row.primarInfo.primarPermit)
        router.push({name: 'reports'})
      }
    },
    scanQR() {
      this.$refs["report-modal"].hide()
      setTimeout(() => {
        eventBus.$emit('start-scan-qr-ecdis')
      }, 500)
    },
    report() {
      this.$refs['report-modal'].hide()
      this.$root.$emit('bv::toggle::collapse', 'manlog-sidebar');
    },
    visibleRows() {
      // return this.$store.state.proto.installationsECDIS.concat(this.$store.state.proto.installationsDevices);
      return this.userStore.installationsECDIS.concat(this.userStore.installationsDevices);
    },
    onDone(from) {
      this.$store.dispatch('proto/updateOrder',
          {
            device_id: this.$parent.$parent.ship.device_id,
            order_number: this.$parent.$parent.ship.order_number,
            order_date: this.$parent.$parent.ship.order_date,
            ecdis_name: this.$parent.$parent.ship.ecdis_name,
            ecdis_imo: this.$parent.$parent.ship.ecdis_imo,
            application_id: this.$parent.$parent.ship.application_id
          }).then(() => {

      })
    },
    onRowSelected(items) {

      console.log(items)

      if (items.length > 0) {
        eventBus.$emit('ecdis-selected', items[0])

        this.$parent.$parent.ship.order_number = Math.floor(1000 + Math.random() * 9000)
        this.$parent.$parent.ship.order_date = new Date().toLocaleDateString()
        this.$parent.$parent.ship.ecdis_name = items[0].applicationId === 11 ? items[0].primarInfo.shipInfo.name : items[0].appState.shipInfo.name
        this.$parent.$parent.ship.ecdis_imo = items[0].applicationId === 11 ? items[0].appState.shipInfo.imo : items[0].appState.shipInfo.imo
        this.$parent.$parent.ship.device_id = items[0].deviceId
        this.$parent.$parent.ship.application_id = items[0].applicationId
      } else {
        this.$parent.$parent.ship.order_number = null
        this.$parent.$parent.ship.order_date = null
        this.$parent.$parent.ship.ecdis_name = null
        this.$parent.$parent.ship.ecdis_imo = null
        this.$parent.$parent.ship.device_id = null
        this.$parent.$parent.ship.application_id = null
      }

    },
    dataProviderSelected(type) {
      // alert(1)
      // this.spinner = true
      // this.$refs["ships-list"].onDone("ships")
      //
      this.onDone()


      this.$store.commit("routes/useRouteForOrder", false)
      this.$store.commit("routes/resetStorage")
      this.$store.dispatch('proto/selectShip', true).then(() => {

        this.$store.dispatch('proto/getAgreementsV2').then(() => {
          // localStorage.setItem('proto', JSON.stringify(store.state.proto));
          // this.spinner = false
          if (type === 'primar') {
            store.commit("fickle/setOrderSource", OrderSource.PRIMAR)
            router.push({name: 'order-primar'}).catch(() => {
            });
          } else if (type === 'ukho') {
            store.commit("fickle/setOrderSource", OrderSource.UKHO)
            router.push({name: 'order-ukho'}).catch(() => {
            });
          }
        })
      })

      setTimeout(() => {
        eventBus.$emit('select-ship-from-ships', "1")
      }, 1000);
    },
  }
}
</script>

<style scoped>
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}

</style>