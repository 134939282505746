export const AppType = Object.freeze({
    MKART: 0,
    NP: 1,
    DEVICES: 2,
    ALL: 3,
    UNKNOWN: 4,
})

export const ActiveTab = Object.freeze({
    DEVICES: 0,
    MANAGE: 1,
    ROUTES: 2,
    DISTRIBUTOR: 3,
    TRANSACTIONS: 4
})

export const AppNames = Object.freeze({
    DEVICES: 'mKart',
    MANAGE: 'Njord Pilot',
})

export const OrderSource = Object.freeze( {
    PRIMAR: 'Primar',
    UKHO: 'UKHO'
})