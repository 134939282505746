import {file} from "paths.macro";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import {eventBus} from "@/main";
import idb from "@/dbase/idb";
import Vue from "vue";
import {daysOld, getENCInfo} from "@/utils/utils";
import * as utils from "@/utils/utils";

const x = require("@/data/ship.json")
// let x = {"name":"Akademik Fedorov","MMSI":1249082384,"IMO":0,"length":147.29,"width":13.46,"height":5.09,"draught":7.70,"ECDIS":"dKart","OS":"Win32","permit":"59011A50E912A93E26390264414E","POS": {"lat":60.00000, "lon":23.00000},"SOG":10.00,"COG":0.00,"HDG":-30.00,"ROT":0.00,"TIME":"2023-10-17T16:30:59","route":{"name":"Hz kuda plyvem","points":[{"lat":60.02298,"lon":29.45528},{"lat":60.04135,"lon":29.41177},{"lat":60.03800,"lon":29.27980},{"lat":60.03207,"lon":29.14727},{"lat":60.03165,"lon":29.00943},{"lat":60.02105,"lon":28.87473},{"lat":60.01575,"lon":28.80738},{"lat":60.00515,"lon":28.67268},{"lat":59.97273,"lon":28.56445},{"lat":59.95710,"lon":28.38367},{"lat":59.98010,"lon":28.29617}]},"history":[{"lat":60.00000,"lon":23.00000,"TIME":"2023-10-17T16:29:29"},{"lat":61.00000,"lon":23.00000,"TIME":"2023-10-17T16:27:49"},{"lat":62.00000,"lon":23.00000,"TIME":"2023-10-17T16:27:24"},{"lat":62.00000,"lon":24.00000,"TIME":"2023-10-17T16:27:13"},{"lat":62.00000,"lon":24.00000,"TIME":"2023-10-17T16:14:40"}]}

const initialState = {
    agreement: {
        is_downloaded: false
    },
    order: {},
    orderSource: '',
    orders_list: {
        is_refresing: false,
        cells: []
    },
    requests: {
        current_request: null,
    },
    qr: {
        shipInfo: null,
        shipLogs: [],
        selectedPermit: null,
        audio: null,
        audio_src: null,
    },
    offline: {
        status: false,
        userDataReady: false,
        userInstallationsReady: false,
        primarEncDataReady: false,
        ukhoEncDataReady: false,
        userDataLoading: false,
        userInstallationsLoading: false,
        encLoading: false,
        requests: [],
        isRequestsExist: false
    },
    routes: {
        objectsInfo: null,
        objectsInfoLoading: false,
        nogoZones: [],
        nogoObjects: [],
        nogoCategories: [],
        metric: [],
        globalRoute: {
            ports: [],
            portsCoordinates: [],
            waypoints: []
        }
    },
    weather: {
        showWind: false,
        showTemperature: false,
        date: '',
        time: '',
        datetime: '',
        weatherwp: [],
        isUpdating: false
    },
    tmp1: null,
    tmp2: null,
    tokens: {
        is_ready: false,
        objectsInfoLoading: false,
        objectsInfo: null,
        log: [],
        rules: [],
        fcXML: null
    },
    all_tokens: [],
    all_tokens_points: [],
    features: []
    // tokens_parsed: false,
    // tokens: {
    //
    //     names: [],
    //     attributes: []
    // }

}
export const fickle = {
    namespaced: true,
    state: initialState,
    actions: {
        getOrder({commit}, id) {
            return new Promise(resolve => {
                let URL = "/api/v1/order/" + id;
                myAxios.get(URL).then(resp => {
                    commit("setOrder", Object.freeze(resp.data));
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 107)
                });
            });
        },
        getDeviceOrders({commit, dispatch}, payload) {
            console.log(file, "getDeviceOrders");

            commit("setDeviceOrders", {cells: [], busy: true});

            return new Promise(resolve => {
                let URL = "/api/v1/orders/deviceid/" + payload.device_id;
                if (payload.user_id) {
                    URL = "/api/v1/admin/orders/deviceid/" + payload.device_id + "?userId=" + payload.user_id;
                }

                myAxiosRetry.get(URL).then(resp => {
                    let orders = resp.data.orders;
                    let cells = []

                    orders.forEach(order => {
                        if (order.isPaid && order.status !== "error") {
                            order.items.forEach(cell => {
                                let daysStill = utils.daysToMonth(order.createdUtc, cell.monthCount)
                                let cellInfo = utils.getENCInfo(cell.id)
                                let band = cellInfo === undefined ? 'unknown' : utils.getBandName(cellInfo.usageBand)

                                let objIndex = cells.findIndex((obj => obj.id === cell.id));
                                if (objIndex === -1) {
                                    cells.push({id: cell.id, created_date: order.createdUtc, months_count: cell.monthCount, days_valid: daysStill, band: band})
                                } else if (cells[objIndex].days_valid <= daysStill) {
                                    cells.splice(objIndex, 1)
                                    cells.push({id: cell.id, created_date: order.createdUtc, months_count: cell.monthCount, days_valid: daysStill, band: band})
                                }
                            })
                        }
                    })

                    commit("setDeviceOrders", {cells: cells, busy: false});
                    resolve()
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 110)
                });
            });
        },
    },
    mutations: {
        setOrder(state, order) {
            state.order = order
        },
        setDeviceOrders(state, payload) {
            state.orders_list.cells = payload.cells
            state.orders_list.is_refresing = payload.busy
        },
        setAgreementsDownloaded(state, flag) {
            state.agreement.is_downloaded = flag
        },
        setSelectedPermit(state, permit) {
            state.qr.selectedPermit = permit
        },
        setQRImages(state, images) {

            let info = state.qr.shipInfo
            let permit = info.permit
            let time = info.TIME


            let objIndex = state.qr.shipLogs.findIndex((obj => obj.permit === permit));

            if (objIndex === -1)
                alert(2)
            else {
                let objIndex1 = state.qr.shipLogs[objIndex].logs.findIndex((obj => obj.time === time));
                if (objIndex1 === -1)
                    alert(4)
                else {
                    state.qr.shipLogs[objIndex].logs[objIndex1]["images"] = images
                }

            }

            // state.qr.images = images
        },
        setQRAudio(state, audio) {

            let info = state.qr.shipInfo
            let permit = info.permit
            let time = info.TIME

            let objIndex = state.qr.shipLogs.findIndex((obj => obj.permit === permit));

            if (objIndex === -1)
                alert(2)
            else {
                let objIndex1 = state.qr.shipLogs[objIndex].logs.findIndex((obj => obj.time === time));
                if (objIndex1 === -1)
                    alert(4)
                else {
                    state.qr.shipLogs[objIndex].logs[objIndex1]["audio"] = audio.blob
                    state.qr.shipLogs[objIndex].logs[objIndex1]["audio_src"] = audio.url
                    state.qr.audio = audio.blob
                    state.qr.audio_src = audio.url
                }

            }

            // state.qr.images = images
        },
        setQR1(state, info) {
          state.qr = info
        },

        setQRInfo(state, info) {
            state.qr.shipInfo = info

            let permit = info.permit
            let time = info.TIME
            let log = {time: info.TIME, info: info}

            let record = {permit: permit, logs: [log]}

            let objIndex = state.qr.shipLogs.findIndex((obj => obj.permit === permit));
            if (objIndex === -1) {
                state.qr.shipLogs.push(record)
                // idb.saveBook({record})
                // addBook
            } else {
                let objIndex1 = state.qr.shipLogs[objIndex].logs.findIndex((obj => obj.time === time));
                if (objIndex1 === -1)
                    state.qr.shipLogs[objIndex].logs.push(log)
            }

        },
        setQRInfoDebug(state) {
            state.qr.shipInfo = x

            let index = 0
            state.qr.shipInfo.logs.forEach(item => {
                item["index"] = index++
            })
        },
        setCurrentRequest(state, request) {

            if (request.startsWith("api/v1/installations"))
                state.requests.current_request = "loading installations"
            if (request.startsWith("api/v1/orders"))
                state.requests.current_request = "loading all orders"
            if (request.startsWith("/api/v1/order/"))
                state.requests.current_request = "loading order"
            if (request.startsWith("/api/v1/orders/deviceid"))
                state.requests.current_request = "loading previous orders"
            if (request.startsWith("/api/v1/agreement/query"))
                state.requests.current_request = "loading agreements info"
            if (request.startsWith("api/v2/agreement"))
                state.requests.current_request = "loading agreement info"
            if (request.includes("update-status"))
                state.requests.current_request = "updating order status"
        },
        setUserDataReady(state, flag) {
            state.offline.userDataReady = flag
        },
        setUserInstallationsReady(state, flag) {
            state.offline.userInstallationsReady = flag
        },
        setPrimarENCDataReady(state, flag) {
            state.offline.primarEncDataReady = flag
        },
        setUKHOENCDataReady(state, flag) {
            state.offline.ukhoEncDataReady = flag
        },
        setUserDataLoading(state, flag) {
            state.offline.userDataLoading = flag
        },
        setUserInstallationsLoading(state, flag) {
            state.offline.userInstallationsLoading = flag
        },
        setENCDataLoading(state, flag) {
            state.offline.encLoading = flag
        },
        setOfflineStatus(state, flag) {
            state.offline.status = flag
        },
        setOfflineRequest(state, payload) {
            alert("Offline, store request for feature")
            state.offline.isRequestsExist = true
            state.offline.requests.push({name: payload.name, params: payload.params, event: payload.event})
        },
        clearOfflineRequestsStatus(state) {
            state.offline.isRequestsExist = false
        },
        setObjectInfo(state, payload) {
            state.routes.objectsInfo = payload
        },
        setGlobalRoute(state, payload) {
            // state.routes.globalRoute.waypoints[0] = {lat: 59.067, lon: 10.652}
            // state.routes.globalRoute.waypoints[1] = {lat: 58.874, lon: 10.402}
            // state.routes.globalRoute.waypoints[2] = {lat: 57.992, lon: 10.099}

            state.routes.globalRoute.ports = payload.ports
            state.routes.globalRoute.portsCoordinates = payload.coordinates

            eventBus.$emit('draw-global-route')
        },
        clearGlobalRoute(state) {
            state.routes.globalRoute.ports = []
        },
        setTokensObjectInfo(state, payload) {
            state.tokens.objectsInfo = payload
        },
        setObjectInfoLoading(state, flag) {
            state.routes.objectsInfoLoading = flag
        },
        setTokensObjectInfoLoading(state, flag) {
            state.tokens.objectsInfoLoading = flag
        },
        setNoGoZones(state, payload) {
            state.routes.nogoZones = payload.zones
            state.routes.nogoObjects = payload.objects
            state.routes.nogoCategories = payload.categories
        },
        deleteNoGoZones(state, name) {
            let index = state.routes.nogoZones.findIndex(p => p === name);
            state.routes.nogoZones.splice(index, 1);
        },
        setMetric(state, metric) {
            state.routes.metric.push(metric)
        },
        clearMetric(state) {
            state.routes.metric = []
        },
        setWeatherSettings(state, payload) {
            state.weather.showWind = payload.showWind
            state.weather.showTemperature = payload.showTemperature
            // state.weather.date = payload.date
            // state.weather.time = payload.time
            // state.weather.datetime = moment(payload.date + " " + payload.time, "YYYY-MM-DD HH:mm").unix()
            // state.weather.date = payload.datetime
            state.weather.datetime = payload.datetimeu

            // console.log("ggg1", payload.date)
            // console.log("ggg1", payload.time)
            //
            //
            // let datetime = moment(payload.date + " " + payload.time, "YYYY-MM-DD HH:mm")
            // console.log("ggg1", datetime.unix())


        },
        setWeatherDateTime(state, datetime) {
            state.weather.datetime = datetime
        },
        setWeatherWP(state, data) {
            Vue.set(state.weather, "weatherwp", data)
        },
        setWeatherUpdating(state, flag) {
            console.log("aaa", flag)
            state.weather.isUpdating = flag
        },
        setOrderSource(state, type) {
            state.orderSource = type
        }
        // setTemp1(state, token) {
        //     state.tmp1 = token
        // },
        // setTemp2(state, token) {
        //     state.tmp2 = token
        // },
    //     setTokensReady(state) {
    //         state.tokens.is_ready = true
    //     },
    //     clearTokens(state, payload) {
    //         state.all_tokens = []
    //     },
    //     setTokens(state, payload) {
    //         state.all_tokens.push({cell:payload.cell, tokens:payload.tokens, points:[]})
    //     },
    //     setTokensFC(state, fcXML) {
    //         state.tokens.fcXML = fcXML
    //     },
    //     setTokensPoints(state, payload) {
    //         let cellIndex = _.findIndex(state.all_tokens, {'cell':payload.cellName})
    //         let cell1 = _.find(state.all_tokens, {'cell':payload.cellName})
    //         console.log("eeee", payload.cellName, cellIndex, cell1)
    //         // state.all_tokens[cellIndex]['points'] = {points:payload.points}
    //         state.all_tokens[cellIndex]['points'] = payload.points
    //     },
    //     setTokensLog(state, message) {
    //         state.tokens.log.push(message)
    //     },
    //     setTokensRules(state, rules) {
    //         state.tokens.rules = rules
    //     },
    //     autoEdit1(state) {
    //         state.all_tokens[0].tokens[0].attributes[1].val = 'not in use'
    //     },
    //     deleteTokenAttribute(state, payload) {
    //         let cell = payload.cell
    //         let tokenId = payload.token_id
    //         let UUID = payload.UUID
    //
    //         let tokens = _.find(state.all_tokens, {cell:cell})['tokens']
    //         let token = _.find(tokens, {"id": tokenId})
    //
    //         _.remove(token.attributes, function (n) {
    //             return n.UUID === UUID;
    //         });
    //
    //         token.complex_attributes.forEach(item => {
    //             _.remove(item.childs, function (n) {
    //                 return n.UUID === UUID;
    //             });
    //         })
    //
    //         token.components.forEach(item => {
    //             _.remove(item.attributes, function (n) {
    //                 return n.UUID === UUID;
    //             });
    //
    //             item.complex_attributes.forEach(item => {
    //                 _.remove(item.childs, function (n) {
    //                     return n.UUID === UUID;
    //                 });
    //             })
    //         })
    //     },
    //     addTokenAttribute(state, payload) {
    //         let name = payload.name
    //         let code = payload.code
    //         let attribute = payload.attribute
    //         let UUID = payload.UUID
    //
    //         let index = _.findIndex(state.all_tokens[0].tokens.components, {'code': code});
    //         state.all_tokens[0].tokens.components[index].attributes.push({UUID: UUID, code: attribute, name: name, val: '<empty>'})
    //     },
    //     editCoordinates(state, payload) {
    //         let cell = payload.cell
    //         let id = payload.id
    //         let lat = payload.lat
    //         let lon = payload.lon
    //
    //         let points = _.find(state.all_tokens, {cell:cell})['points']
    //         let coordinates = _.find(points, {id:id})
    //         if (lat !== '')
    //             coordinates.lat = lat
    //         else
    //             coordinates.lon = lon
    //
    //     },
    //     editTokenAttribute(state, payload) {
    //         console.log("gg", payload)
    //
    //         let cell = payload.cell
    //         let tokenId = payload.token_id
    //         let UUID = payload.UUID
    //         let val = payload.val
    //
    //         console.log("oooo", tokenId, UUID)
    //
    //         let tokens = _.find(state.all_tokens, {cell:cell})['tokens']
    //         console.log("oooo", tokens)
    //
    //         let token = _.find(tokens, {"id": tokenId})
    //
    //         // let token = _.find(state.all_tokens[0].tokens, {"id": tokenId})
    //
    //         let index = _.findIndex(token.attributes, {'UUID': UUID});
    //         if (index >= 0)
    //             _.set(token.attributes, [index, 'val'], val)
    //         else {
    //             index = _.findIndex(token.complex_attributes.attr, {'UUID': UUID});
    //             if (index >= 0)
    //                 _.set(token.complex_attributes, [index, 'val'], val)
    //             else {
    //                 token.components.forEach(item => {
    //                     index = _.findIndex(item.attributes, {'UUID': UUID});
    //
    //                     if (index >= 0)
    //                         _.set(item.attributes, [index, 'val'], val)
    //                     else {
    //                         token.complex_attributes.forEach(item => {
    //                             index = _.findIndex(item.childs, {'UUID': UUID});
    //                             if (index >= 0)
    //                                 _.set(item.childs, [index, 'val'], val)
    //                             else {
    //                                 token.components.forEach(item => {
    //                                     item.complex_attributes.forEach(item => {
    //                                         index = _.findIndex(item.childs, {'UUID': UUID});
    //                                         if (index >= 0)
    //                                             _.set(item.childs, [index, 'val'], val)
    //                                     })
    //                                 })
    //                             }
    //                         })
    //                     }
    //                 });
    //             }
    //         }
    //
    //
    //         console.log(UUID, index)
    //     }
    //
    },
}