<template>
  <div id="app">

    <b-modal id="modal-devices" title="Offline mode warning" v-model="openOfflineWarning" ok-only>
      <div class="text-sm-left" id="up">
        Your Internet connection is lost. The site works in restricted mode.
      </div>
    </b-modal>

      <b-modal id="modal-devices" title="Help" v-model="openHelp" ok-only>
      <div class="text-sm-left" id="up">
        <a href="#help1"><b-icon-stop></b-icon-stop>&nbsp;HOW IT WORKS</a><br>
        <a href="#help2"><b-icon-stop></b-icon-stop>&nbsp;REGISTRATION</a><br>
        <a href="#help3"><b-icon-stop></b-icon-stop>&nbsp;SHIPS' DATA</a><br>
        <a href="#help4"><b-icon-stop></b-icon-stop>&nbsp;ENC SELECTION</a><br>
        <a href="#help5"><b-icon-stop></b-icon-stop>&nbsp;PAYMENT</a><br>
        <a href="#help6"><b-icon-stop></b-icon-stop>&nbsp;DELIVERY</a><br>
        <a href="#help7"><b-icon-stop></b-icon-stop>&nbsp;TRANSACTIONS</a><br>

        <div id="help1">
          <hr>
          <h4><b-alert show variant="success">HOW IT WORKS</b-alert></h4>
          <hr>
          <p>
            The Get ENC service was specifically designed to simplify, streamline, and make the official Electronic Navigational Charts (ENCs) purchasing process more cost-effective. At its core, the idea behind this service is to leverage mobile devices for the retrieval of Electronic Chart Display and Information System (ECDIS) data and facilitate the delivery of ENCs and permits directly to vessels. However, it's important to note that PC and desktop devices can also seamlessly integrate with this platform.
          </p>
          <p>
            Get ENC offers a comprehensive solution to manage and oversee critical navigational data necessary for ensuring safety at sea. It not only serves as a tool for individual mariners but is also adaptable for use within corporate systems and the procurement process. Additionally, it facilitates the exchange of ship-shore information and fosters seamless communication within a company or organization.
          </p>
          <p>
            Furthermore, Get ENC is a versatile service that caters to a wide range of users, including individuals, companies, and can even be embedded into original equipment manufacturer (OEM) products and services, enhancing its adaptability and applicability across the maritime industry.
          </p>
          <ol>
            <li>
              The initial step in the user registration process involves receiving a password via the provided email address. Following this, users are prompted to input essential ship and Electronic Chart Display and Information System (ECDIS) information. This can be done in two ways: through straightforward manual data entry or the more advanced method of scanning relevant information and QR codes using a mobile device.
            </li>
            <li>
              When it comes to Electronic Navigational Charts (ENCs) selection, users have multiple options at their disposal. They can simply click on the screen to choose their desired ENCs, and they can also plan their routes, benefitting from editing features to customize their navigation experience. An automatic route planning feature is available for added convenience, as well as import/export functionality to seamlessly manage route data.
            </li>
            <li>
              Making payments is hassle-free with the choice of using a credit card or opting for a payment agreement.
            </li>
            <li>
              Users have the flexibility to adjust their data shipment preferences through the configuration menu. However, it's worth noting that email remains the most common and widely used method for data delivery.
            </li>
          </ol>
          <p>
            For user support and assistance, the Get ENC help desk operates around the clock, 24/7. Furthermore, customized and specialized services can be arranged through individual agreements, ensuring that users receive the tailored support they need.
          </p>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
          <hr>
        </div>

        <div id="help2">
          <h4><b-alert show variant="success">REGISTRATION</b-alert></h4>
          <hr>
          <p>
            To register for the Get ENC service, you only need to provide your email address, ensuring a quick and hassle-free signup process.
          </p>
          <h6>
            <b-alert show>Step 1</b-alert>
          </h6>
          <ol>
            <li>
              Go to E-shop
            </li>
            <li>
              Сlick the "Registration" button
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3165-6461-4330-b364-653162623034/-/format/webp/01.jpg">
          <h6>
            <b-alert show>Step 2</b-alert>
          </h6>
          <ol>
            <li>
              Enter your email and click "Submit"
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild6436-3638-4663-b639-313465616363/-/format/webp/02.jpg">
          <h6>
            <b-alert show>Step 3</b-alert>
          </h6>
          <ol>
            <li>
              An email with a generated password will be sent to the email address you provided.
            </li>
            <li>
              Enter your email in the 'email' field, the one you used to receive the password. In the 'password' field, enter the password that was sent to you in the email.
            </li>
            <li>
              Click the "Login" button
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3966-3533-4864-b733-613135313536/-/format/webp/03.jpg">
          <br>
          <b-alert variant="warning" show>Note: Use above credentials for activating your profile on WEB or in App.</b-alert>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
          <hr>
        </div>

        <div id="help3">
          <h4><b-alert show variant="success">SHIPS' DATA</b-alert></h4>
          <hr>
          <h5>
            MANUAL INPUT
          </h5>
          <h6>
            <b-alert show>Step 1</b-alert>
          </h6>
          <ol>
            <li>
              Go to the 'Ships' tab and click on 'Add new ship'.
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3764-3432-4232-a430-613637636431/-/format/webp/ships_data_-1.png">
          <br>
          <h6>
            <b-alert show>Step 2</b-alert>
          </h6>
          <ol>
            <li>
              Fill in the form fields.
            </li>
            <li>
              'Notification emails' - specify one or multiple email addresses separated by commas to which all financial information related to ENC purchases will be sent.
            </li>
            <li>
              'Delivery email' - please provide the email address to which purchased ENCs will be delivered.
            </li>
            <li>
              'Ship name' - in this field, specify the name of the ship for which you plan to purchase ENCs.
            </li>
            <li>
              'IMO' - this is a unique identification number assigned to every maritime vessel registered or operating under the jurisdiction of the International Maritime Organization (IMO). This number consists of a three-letter abbreviation 'IMO,' followed by a seven-digit numeral. The initial six digits represent the vessel's unique sequential number, while the seventh digit serves as a checksum used to validate the accuracy of the number.
            </li>
            <li>
              'Permit' - this is a permission or license that allows a user to obtain and use Electronic Navigational Charts (ENCs) on a vessel equipped with the Electronic Chart Display and Information System (ECDIS).
            </li>
            <li>
              'Country' - specify the country of the vessel's registration.
            </li>
            <li>
              'MMSI' (optional) - (Maritime Mobile Service Identity) this is a unique nine-digit number assigned to maritime and inland waterway vessels for identification in radio communication systems, such as VHF communication and the Automatic Identification System (AIS).
            </li>
            <li>
              'Call sign' (optional) - this is a unique audio or radio symbol that identifies a specific radio station, vessel, military facility, or any other radio communication device. This symbol can consist of letters, numbers, or their combination and often serves to indicate the country, region, or organization to which the radio station or device belongs.
            </li>
            <li>
              'Ship category' (optional) - select the vessel category from the drop-down list.
            </li>
            <li>
              'ECDIS brand' (optional) - choose the ECDIS manufacturer from the drop-down list.
            </li>
            <li>
              'ECDIS OS' (optional) - select from the drop-down list which operating system your ECDIS uses.
            </li>
            <li>
              'Backup permit' (optional) - If there are auxiliary ECDIS devices on the vessel, you can specify them in this form field.
            </li>
            <li>
              After filling in all the fields, click the 'Save' button.
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild6130-6266-4631-a635-326162333363/-/format/webp/ships_data_-2.png">
          <br>
          <h5>
            TEXT RECOGNITION
          </h5>
          <h6>
            <b-alert show>Step 1</b-alert>
          </h6>
          <ol>
            <li>
              To simplify data entry and minimize errors, consider displaying your ENC User Permit number on your ECDIS screen.
            </li>
            <li>
              Utilize your smartphone's camera, which supports text recognition, to capture the content displayed on the ECDIS screen. Recognize and extract the ENC User Permit number, then copy it to your clipboard for further use.
            </li>
            <li>
              Now, you can paste the copied number while filling in the vessel's data, simplifying the process and ensuring accuracy.
            </li>
          </ol>
          <img class="img-responsive" src="https://static.tildacdn.com/tild3562-3835-4163-a534-623537636436/scan_-_2.jpg">
          <br>
          <h5>
            QR-CODE READING
          </h5>
          <p>
            If your ECDIS supports data provision in the form of a generated QR code, you can simply scan this QR code using your smartphone's camera, and the ECDIS data will be automatically populated within the Get ENC system.
          </p>
          <br>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
          <hr>
        </div>

        <div id="help4">
          <h4><b-alert show variant="success">ENC SELECTION</b-alert></h4>
          <hr>
          <h5>
            BY PLANNED ROUTE
          </h5>
          <h6>
            <b-alert show>Step 1</b-alert>
          </h6>
          <ol>
            <li>
              Go to the 'Ships' tab and select the method of receiving ENC - to 'Ships' or to 'Devices'.
            </li>
            <li>
              Choose your ship or device from the list and click the 'Select' button.
            </li>
          </ol>
          <img class="img-responsive" src="https://static.tildacdn.com/tild6638-6665-4065-b636-643963626138/enc_select_-2.png">
          <br>
          <h6>
            <b-alert show>Step 2</b-alert>
          </h6>
          <ol>
            <li>
              Select the 'Routes' section and ensure that the 'Manual mode' is enabled in the bottom right corner of the map.
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild6130-3461-4433-a661-613330663632/-/format/webp/enc_select_-3.png">
          <br>
          <h6>
            <b-alert show>Step 3</b-alert>
          </h6>
          <ol>
            <li>
              To define the start, intermediate waypoints, and end of the route, left-click on the map, and then click 'Add'.
            </li>
            <li>
              When you finish plotting the route manually, click the 'Add to Order' button.
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3136-3537-4434-b362-356136383866/-/format/webp/enc_select_-5.png">
          <br>
          <h6>
            <b-alert show>Step 5</b-alert>
          </h6>
          <ol>
            <li>
              Get ENC will automatically select charts according to your route.
            </li>
            <li>
              If you want to remove charts that do not suit your needs, toggle off the corresponding switch.
            </li>
            <li>
              If you wish to add charts that are not part of your route, simply left-click on the desired area of the map.
            </li>
          </ol>
          <br>
          <b-alert variant="warning" show>Note: To view more various layers for ENC, click on the 'Layers' icon.</b-alert>
          <br>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3331-3239-4132-b532-616434393065/-/format/webp/enc_select_-7.png">
          <br>
          <h5>
            BY AUTO ROUTE
          </h5>
          <h6>
            <b-alert show>Step 1</b-alert>
          </h6>
          <ol>
            <li>
              Go to the 'Ships' tab and select the method of receiving ENC - to 'Ships' or to 'Devices'.
            </li>
            <li>
              Choose your ship or device from the list and click the 'Select' button.
            </li>
          </ol>
          <img class="img-responsive" src="https://static.tildacdn.com/tild6638-6665-4065-b636-643963626138/enc_select_-2.png">
          <br>
          <h6>
            <b-alert show>Step 2</b-alert>
          </h6>
          <ol>
            <li>
              Select the 'Routes' section and switch to 'Auto-mode' in the bottom right corner of the map.
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3537-3366-4363-b464-613339353939/-/format/webp/auto_route_-1.png">
          <br>
          <h6>
            <b-alert show>Step 3</b-alert>
          </h6>
          <ol>
            <li>
              Left-click on the map to set the starting point of the route, then click the 'Start point' button.
            </li>
            <li>
              Left-click on the map to set the end point of the route, then click the 'Finish point' button.
            </li>
            <li>
              Get ENC will automatically plot the optimal route, and if necessary, you can manually edit the suggested route. When you finish plotting the route, click the 'Add to Order' button
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild6131-3936-4464-b333-313636353062/-/format/webp/auto_route_-3.png">
          <br>
          <h6>
            <b-alert show>Step 4</b-alert>
          </h6>
          <ol>
            <li>
              Get ENC will automatically select charts according to your route.
            </li>
            <li>
              If you want to remove charts that do not suit your needs, toggle off the corresponding switch.
            </li>
            <li>
              If you wish to add charts that are not part of your route, simply left-click on the desired area of the map.
            </li>
          </ol>
          <b-alert variant="warning" show>Note: To view more various layers for ENC, click on the 'Layers' icon.</b-alert>
          <br>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3033-6330-4333-a630-666531363837/-/format/webp/auto_route_-5.png">
          <br>
          <h5>
            IMPORT/EXPORT
          </h5>
          <p>
            In Get ENC, you have the option to import your ENC list in the form of an Excel file, streamlining the process and enhancing convenience for managing your data.
          </p>
          <h6>
            <b-alert show>Step 1</b-alert>
          </h6>
          <ol>
            <li>
              Go to the 'Ships' tab and select the method of receiving ENC - to 'Ships' or to 'Devices'.
            </li>
            <li>
              Choose your ship or device from the list and click the 'Select' button.
            </li>
          </ol>
          <img class="img-responsive" src="https://static.tildacdn.com/tild6638-6665-4065-b636-643963626138/enc_select_-2.png">
          <br>
          <h6>
            <b-alert show>Step 2</b-alert>
          </h6>
          <ol>
            <li>
              Click the 'Import' button to initiate the process.
            </li>
            <li>
              In the opened window, specify the cell names in the Excel file (separated by commas or on new lines) where the required ENC data is located for importing.
            </li>
          </ol>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3566-6433-4062-b764-396164656332/-/format/webp/import-2.jpg">
          <br>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
          <hr>
        </div>

        <div id="help5">
          <h4><b-alert show variant="success">PAYMENT</b-alert></h4>
          <hr>
          <p>
            The payment can be done by credit card or via agreement.
          </p>
          <h5>
            BY CREDIT CARD
          </h5>
          <ul>
            <li>
              After selecting all the required ENCs, click the 'Submit' button.
            </li>
            <li>
              Review the placed order, and to proceed, click the 'OK' button.
            </li>
            <li>
              Select a payment system from the options provided.
            </li>
            <li>
              Click to proceed to payment
            </li>
            <li>
              Choose the payment method - card, enter the card details for payment, and click the 'Pay' button.
            </li>
          </ul>
          <img class="img-responsive" src="https://static.tildacdn.com/tild3762-3736-4463-b837-643636363264/payment-3.jpg">
          <br>
          <img class="img-responsive" src="https://static.tildacdn.com/tild3063-3935-4737-b263-383233643739/payment-5.jpg">
          <br>
          <h5>
            VIA AGREEMENT
          </h5>
          <ul>
            <li>
              To make a bank payment based on an agreement, please get in touch with us through the <a target="_blank" href="https://get-enc.com/contact-us">contact form</a> provided for further assistance and instructions.
            </li>
          </ul>
          <br>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
          <hr>
        </div>

        <div id="help6">
          <h4><b-alert show variant="success">DELIVERY</b-alert></h4>
          <hr>
          <p>
            By default, data is automatically sent to the designated email address. However, you have the option to customize data transmission settings through the configuration menu.
          </p>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild3837-3736-4562-a532-653865396466/-/format/webp/auto_route_-5.png">
          <br>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
          <hr>
        </div>

        <div id="help7">
          <h4><b-alert show variant="success">TRANSACTIONS</b-alert></h4>
          <hr>
          <p>
            You have the option to access a comprehensive record of all your transactions by navigating to the 'Transactions' tab
          </p>
          <img class="img-responsive" src="https://thumb.tildacdn.com/tild6565-3665-4630-b565-623730643337/-/format/webp/transaction_blur.jpg">
          <br>
          <a href="#up"><b-icon-arrow-up></b-icon-arrow-up>Up</a><br><br>
        </div>

      </div>
    </b-modal>

<!--    <b-sidebar id="sidebar-right" title="Debug" right shadow>-->
<!--      <_debug></_debug>-->
<!--    </b-sidebar>-->

    <b-navbar v-show="this.$route.name !== 'loader' && this.$route.name !== 'pdf'" toggleable="lg" type="dark" variant="info" position: sticky>
      <b-navbar-brand v-if="IS_MKART" href="#">mKart</b-navbar-brand>
      <b-navbar-brand v-if="IS_NP" href="#">Njord Pilot</b-navbar-brand>
<!--      <b-navbar-brand v-if="IS_GETENC" href="#">Get ENC</b-navbar-brand>-->

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="auth.status.loggedIn && !IS_GETENC">
<!--          <loc-link v-if="is_admin" :to="'ecdis'">ECDIS</loc-link>-->
<!--          <loc-link v-if="!is_mconnect_user && !IS_NP" :to="'ecdis'">ECDIS</loc-link>-->
          <loc-link v-if="!is_mconnect_user" :to="'devices'">My Devices</loc-link>
          <loc-link v-if="!is_mconnect_user && !IS_NP" :to="'ecdis'">ECDIS</loc-link>
          <loc-link v-if="!is_mconnect_user && valid_user && is_geocontent_readay && !IS_NP" :to="'routes'">Routes</loc-link>
<!--          <loc-link v-if="valid_user && is_geocontent_readay" :to="'charts'">Charts</loc-link>-->
<!--          <loc-link :to="'charts'">Charts</loc-link>-->
<!-- TODO:CATALOG          <loc-link :to="'charts'">Charts</loc-link>-->
<!--          <loc-link v-if="!is_mconnect_user" :to="'viewer'">Map Viewer</loc-link>-->
          <loc-link v-if="is_admin" :to="'transactions'">Transactions</loc-link>
          <loc-link v-if="is_admin" :to="'distributor'">My Agreements</loc-link>
          <loc-link v-if="is_admin" :to="'manage'">Manage</loc-link>
          <b-nav-item @click="help()">Help</b-nav-item>
<!--          <b-nav-item v-if="is_admin && !IS_NP" @click="show_debug()">Debug</b-nav-item>-->
        </b-navbar-nav>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template>
            <b-icon v-if="2" icon="chevron-bar-up"></b-icon>
            <b-icon v-else icon="chevron-bar-down"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-navbar-nav v-if="auth.status.loggedIn && IS_GETENC">
          <b-nav-item class="mr-5" href="https://get-enc.com">Get ENC</b-nav-item>

<!--          <b-nav-item-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>-->
<!--            <template #button-content>-->
<!--              <b-icon icon="basket" aria-hidden="true"></b-icon>-->
<!--            </template>-->
<!--            <b-dropdown-item @click="orderPrimar">Primar (ENC)</b-dropdown-item>-->
<!--            <b-dropdown-item @click="orderUKHO()">UKHO (AVCS, ADP, ENP)</b-dropdown-item>-->
<!--          </b-nav-item-dropdown>-->

<!--          <loc-link v-if="this.$route.name !== 'ships'" :to="'proxy-orders'"><b-icon icon="basket" aria-hidden="true"></b-icon></loc-link>-->

<!--          <b-nav-item-dropdown-->

<!--              id="my-nav-dropdown"-->
<!--              text="Order"-->
<!--              toggle-class="nav-link-custom"-->
<!--              left-->
<!--          >-->
<!--              <b-icon icon="power" aria-hidden="true"></b-icon>-->
<!--              <b-dropdown-item @click="orderPrimar"><b-icon icon="power" aria-hidden="true"></b-icon>Primar (ENC)</b-dropdown-item>-->
<!--              <b-dropdown-item @click="orderUKHO()">UKHO (AVCS, ADP, ENP)</b-dropdown-item>-->

<!--          </b-nav-item-dropdown>-->

<!--          <loc-link v-if="this.$route.name !== 'ships'" :to="'proxy-orders'">Order</loc-link>-->
<!--          <loc-link v-if="this.$route.name !== 'ships'" :to="'proxy-orders'">Primar</loc-link>-->
          <loc-link :to="'routes'">Routes</loc-link>
          <loc-link :to="'ships'">Ships</loc-link>
<!--          <loc-link v-if="this.$route.name !== 'ships'" :to="'proxy-routes'">Routes</loc-link>-->
<!--          <loc-link v-if="this.$store.state.proto.ship_selected && (this.$route.name === 'charts' || this.$route.name === 'routes')" :to="'routes'">Routes</loc-link>-->
          <loc-link :to="'transactions'">Transactions</loc-link>
          <b-nav-item v-if="is_distributor" :to="'agreement'" @click="agreements()">Agreements</b-nav-item>
          <loc-link :to="'deliveries'">Deliveries</loc-link>
          <loc-link :to="'logbook'">Logbook</loc-link>
<!--          <b-nav-item @click="">Config</b-nav-item>-->
          <b-nav-item @click="help()">Help</b-nav-item>
          <b-nav-item @click="offline()" v-if="this.$store.state.fickle.offline.status" right>[Connection lost]</b-nav-item>
        </b-navbar-nav>


<!--        <b-navbar-nav v-if="!auth.status.loggedIn">-->
<!--          <loc-link v-if="!is_mconnect_user" :to="'viewer'">Map Viewer</loc-link>-->
<!--        </b-navbar-nav>-->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

<!--          <b-nav-item-dropdown text="Lang" right>-->
<!--            <b-dropdown-item href="#">EN</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">NO</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">IT</b-dropdown-item>-->
<!--          </b-nav-item-dropdown>-->

          <span v-if="auth.status.loggedIn">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                {{ auth.user.email }} <span v-if="auth.user.role === 'admin'"> (admin)</span>
              </template>
              <b-dropdown-item disabled href="#">Profile</b-dropdown-item>
              <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </span>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="container-fluid">
      <div v-show="alert.message" class="alert alert-danger" role="alert">
        {{ alert.message }}
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LocLink from "@/components/LocLink";
import {AppType, OrderSource} from "@/utils/config";
//import LocLinkDrop from "@/components/LocLinkDrop";
// import Debug from "@/Debug.vue";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import axios from "axios";

// import { loadProgressBar } from 'axios-progress-bar'
import '@/assets/styles/styles.css';
import {eventBus} from "@/main";
import store from "@/store";
import router from "@/router";
// import {myAxiosRetry} from "@/services/myAxios";

export default {
  data() {
    return {
      openHelp: false,
      openOfflineWarning: false,
      arrayTest: [1, 2]
    }
  },

  components: {LocLink},

  mounted() {

    // loadProgressBar({ minimum: 0.1 }, myAxiosRetry())
    this.$store.dispatch("current/getWebUserLocation");

     //const x = useRoutesStore() //так делать нельзя

    // TODO
    // alert("loaded started")
    // store.dispatch('proto/loadStorage').then(() => {
    //   // alert("loaded finished")
    // });
    //
    // store.dispatch('loadStorage').then(() => {
    //   // alert("loaded finished")
    // });

  },
  computed: {
    IS_MKART() {
      return !IS_GETENC && !IS_NP
    },
    IS_GETENC() {
      return IS_GETENC
    },
    IS_NP() {
      return IS_NP
    },
    is_mconnect_user() {
      return this.$store.state.current.user_type.is_mconnect;
    },
    valid_user() {
      return this.$store.state.current.profile.email;
    },
    is_geocontent_readay() {
      return !this.$store.state.current.loading.geocontent && this.$store.state.manage.selected_geocontent;
    },
    alert() {
      return this.$store.state.alert;
    },
    auth() {
      return this.$store.state.auth;
    },
    is_distributor() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user.role === "distributor"
    },
    is_admin() {
      let user = JSON.parse(localStorage.getItem('user'));

      const allowedIP = ['46.252.242.42', '87.117.58.251']

      if (user.role === "admin") {
        if (IS_NP) {
          return true;
        } else {
          if (allowedIP.includes(this.$store.state.current.webuser.ip))
            return true;
        }
      }
      return false;
    }
  },
  watch: {
    $route(to) {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
    },
  },
  methods: {
    agreements() {
      eventBus.$emit('return-to-agreement')
    },
    app_email_link() {
      if (!this.IS_NP)
        return "mailto:info@mkart.fi"
      else
        return "mailto:support@njordpilot.com"
    },
    app_email() {
      if (!this.IS_NP)
        return 'info@mkart.fi'
      else
        return 'support@njordpilot.com'
    },

    logout() {
      this.$store.dispatch("auth/logout");
    },
    // show_debug() {
    //   this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    // },
    // orderPrimar() {
    //   store.commit("fickle/setOrderSource", OrderSource.PRIMAR)
    //   router.push({name: 'proxy-orders'});
    // },
    // orderUKHO() {
    //   store.commit("fickle/setOrderSource", OrderSource.UKHO)
    //   router.push({name: 'proxy-orders'});
    // },
    help() {
      this.openHelp = true;
    },
    offline() {
      this.openOfflineWarning = true;
    }
  },
}
</script>
<style scoped>
.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
  display: block;
  width: 100%;
  height: auto;
}
</style>