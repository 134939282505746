<template>
<div>
  <b-sidebar width="500px" ref="manlog-sidebar" id="manlog-sidebar" shadow>
    <b-container v-show="!photoInput">
      <b-form size="sm" @submit="addReport()">
        <b-form-group size="sm" label="LAT:" label-for="input-1">
          <b-form-input size="sm" id="input-1" v-model="form.lat" :state="latState" type="text" placeholder="" required></b-form-input>
        </b-form-group>
        <b-form-group size="sm" label="LON:" label-for="input-2">
          <b-form-input size="sm" id="input-1" v-model="form.lon" :state="lonState"  type="text" placeholder="" required></b-form-input>
        </b-form-group>
        <b-form-group size="sm" label="HDG:" label-for="input-3">
          <b-form-input size="sm" id="input-1" v-model="form.hdg" type="text" placeholder="optional"></b-form-input>
        </b-form-group>
        <b-form-group size="sm" label="SOG:" label-for="input-4">
          <b-form-input size="sm" id="input-1" v-model="form.sog" type="text" placeholder="optional"></b-form-input>
        </b-form-group>
        <b-form-group size="sm" label="COG:" label-for="input-5">
          <b-form-input size="sm" id="input-1" v-model="form.cog" type="text" placeholder="optional"></b-form-input>
        </b-form-group>
        <b-form-group size="sm" label="ROT:" label-for="input-6">
          <b-form-input size="sm" id="input-1" v-model="form.rot" type="text" placeholder="optional"></b-form-input>
        </b-form-group>
        <b-form-group size="sm" label="MESSAGE" label-for="input-7">
          <b-form-input size="sm" id="input-1" v-model="form.message" type="text" placeholder="optional"></b-form-input>
        </b-form-group>
        <div>
          <b-button size="sm" type="button" @click="addReport()" :disabled="!(latState && lonState)" variant="info">Save & Attach photo</b-button>&nbsp;
<!--          <b-button size="sm" type="button" @click="" variant="info" disabled>Attach Photo</b-button>&nbsp;-->
        </div>
        <br>
      </b-form>
    </b-container>
    <b-container v-show="photoInput">
      <div class=card style="max-height: 80vh; overflow-y: scroll">
        <file-pond-input></file-pond-input>
      </div>
      <b-row class="mt-2">

        <!--          <b-col>-->
        <!--            <b-button size="sm" variant="warning" @click="camera()" class="mr-1">Camera</b-button>-->
        <!--          </b-col>-->

        <b-col class="text-right">
          <b-button size="sm" variant="warning" @click="addPhotos()" class="mr-1">Accept</b-button>
          <b-button size="sm" variant="warning" @click="cancelPhotos()" lass="mr-1">Cancel</b-button>
        </b-col>
      </b-row>

    </b-container>
  </b-sidebar>
</div>
</template>

<script>
import FilePondInput from "@/components/FilePondInput";
import store from "@/store";
// import qrcode from "@/data/ship2.json";

export default {
  name: "ManLog",
  components: {FilePondInput},
  data() {
    return {
      windowWidth: window.innerWidth,
      photoInput: false,
      form: {
        lat: '',
        lon: '',
        hdg: '',
        sog: '',
        cog: '',
        rot: '',
        message: ''
      }
    }
  },
  mounted() {

      // this.$root.$off('bv::toggle::collapse')
      // this.$root.$on('bv::toggle::collapse', (bvEvent, modalId) => {
        // if (this.is_mobile)
        //   navigator.geolocation.getCurrentPosition(success, error);
          // this.$refs['manlog-sidebar'].show();
          // this.$refs['manlog-sidebar'].toggle()
          // this.$root.$emit('bv::toggle::collapse', 'manlog-sidebar')
      // })

      // this.$root.$on('bv::sidebar::show', (bvEvent, modalId) => {
      //   alert(2)
      //   console.log('Modal is about to be shown', bvEvent, modalId)
      // })

    if (this.is_mobile)
      navigator.geolocation.getCurrentPosition(success, error);

    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    const success = (position) => {
      this.form.lat = String(position.coords.latitude);
      this.form.lon = String(position.coords.longitude);

      // console.log("pos ", latitude, longitude)
    };

    const error = (err) => {
      console.log(error)
    };


  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    latState() {
      return this.form.lat.length > 0
    },
    lonState() {
      return this.form.lon.length > 0
    },
  },
  methods: {
    addReport() {
      console.log(this.form.lat)
      console.log(this.form.lon)
      console.log(this.$parent.selected_row)

      console.log(this.$parent.selected_row.primarInfo.primarPermit)
      console.log(this.$parent.selected_row.primarInfo.shipInfo.name)

      let report = {
        TIME: new Date().toISOString(),

        name: this.$parent.selected_row.primarInfo.shipInfo.name,
        HDG: this.form.hdg ? Number(this.form.hdg) : undefined,
        SOG: this.form.sog ? Number(this.form.sog) : undefined,
        COG: this.form.cog ? Number(this.form.cog) : undefined,
        ROT: this.form.rot ? Number(this.form.rot) : undefined,
        DTG: undefined,
        TTG: '',

        message: this.form.message,

        POS: {
          lat: this.form.lat,
          lon: this.form.lon
        },
        route: {
          name: "",
          points: []
        },
        history: [],
        permit: this.$parent.selected_row.primarInfo.primarPermit
      }


      store.commit("fickle/setQRInfo", report)

      this.photoInput = true
      // this.$refs['manlog-sidebar'].hide()
    },
    addPhotos() {
      this.photoInput = !this.photoInput;
      this.$refs['manlog-sidebar'].hide()
    },
    cancelPhotos() {
      this.photoInput = !this.photoInput;
      this.$refs['manlog-sidebar'].hide()
    }



  }
}
</script>

<style scoped>

</style>