<template>
  <div>
    <!--    <b-container v-show="momentaryStore.isBusy" fluid>-->
    <!--      <div align="center" class="text-centercenter text-danger my-2">-->
    <!--        <b-spinner class="align-middle"></b-spinner>-->
    <!--        <strong>&nbsp;Loading...</strong>-->
    <!--      </div>-->
    <!--    </b-container>-->

    <b-overlay :show="!userStore.isInstallationsLoaded" no-center opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isInstallationsLoaded">Loading installations...</p>
        </div>
      </template>

      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card header="DELIVERIES">
              <deliveries ref="deliveries"></deliveries>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import Deliveries from "@/views/deliveries/Deliveries.vue";
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {loadInstallations, loadOrders} from "@/helpers/api";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";

export default {
  components: {Deliveries},
  data() {
    return {
      selected_ship: "",
      ships: []
    }
  },
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);
      this.$refs['deliveries'].checkUpdates()
    }
  },
  mounted() {
    //TODO
    // this.deviceStore.loadData()
    loadInstallations().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
        this.selected_ship = this.userStore.getShips[0].value
      this.ships = this.userStore.getShips
    })
  },
  computed: {
    ...mapStores(useMomentaryStore, useDeviceStore, useUserStore),
  }
}
</script>
<style scoped>
</style>