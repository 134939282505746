<template>
  <div>
    <b-sidebar width="600px" id="qr-details" shadow>
      <div v-show="!photoInput">
        <div class="px-3 py-2" v-if="qr">
          <b-badge variant="light">ECDIS DETAILS</b-badge>
          <br><br>
          <b-row class="mt-2">
            <b-col cols="5">
              <b-button v-if="findInstallation === -1" size="sm" variant="info" @click="addECDIS()" class="mr-1">Add ECDIS</b-button>
              <b-button v-else size="sm" variant="info" @click="updateECDIS()" class="mr-1">Update ECDIS</b-button>
            </b-col>
            <b-col class="text-right">
              <b-button-group>

                <b-button size="sm" variant="info" class="mr-1" v-if="!audio.is_recording" @click="recordAudio"><b-icon icon="mic"></b-icon></b-button>
                <b-button size="sm" variant="danger" class="mr-1" v-else @click="stopAudio"><b-icon icon="mic-mute"></b-icon></b-button>

                <!--              <b-button size="sm" variant="warning" @click="addAudio()" class="mr-1">Add audio</b-button>-->
                <b-button size="sm" variant="info" @click="addPhotos()" class="mr-1"><b-icon icon="camera"></b-icon></b-button>
                <b-button size="sm" variant="info" @click="showOnMap()" class="mr-1"><b-icon icon="map"></b-icon></b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <b-card>


            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>Ship Name:</b></b-col>
              <b-col class="listsmall">
                <span class="smt14" v-if="findInstallation !== -1 && qr.name !== this.$store.state.proto.installationsECDIS[findInstallation].primarInfo.shipInfo.name">
                  {{ qr.name }}
                </span>
                <span v-else>{{ qr.name }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>MMSI:</b></b-col>
              <b-col class="listsmall">
                <span class="smt14" v-if="findInstallation !== -1 && qr.MMSI !== this.$store.state.proto.installationsECDIS[findInstallation].primarInfo.shipInfo.mmsi">
                  {{ qr.MMSI }}
                </span>
                <span v-else>{{ qr.MMSI }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>IMO:</b></b-col>
              <b-col class="listsmall">
                <span class="smt14" v-if="findInstallation !== -1 && qr.IMO !== this.$store.state.proto.installationsECDIS[findInstallation].primarInfo.shipInfo.imo">
                  {{ qr.IMO }}
                </span>
                <span v-else>{{ qr.IMO }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>Dimensions:</b></b-col>
              <b-col class="listsmall"><span class="smt14">{{ qr.length }} {{ qr.width }} {{ qr.height }}</span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>Draught:</b></b-col>
              <b-col class="listsmall"><span class="smt14">{{ qr.draught }}</span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>ECDIS:</b></b-col>
              <b-col class="listsmall">{{ qr.ECDIS }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>OS:</b></b-col>
              <b-col class="listsmall">{{ qr.OS }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-left"><b>Permit:</b></b-col>
              <b-col class="listsmall">{{ qr.permit }}</b-col>
            </b-row>
          </b-card>
        </div>
      </div>

<!--      <div v-if="audioInput">-->
<!--        <b-button v-if="!audio.is_recording" @click="recordAudio">Record</b-button>-->
<!--        <b-button v-else @click="stopAudio">Stop</b-button>-->
<!--      </div>-->

      <div v-if="photoInput">
        <div class=card style="max-height: 80vh; overflow-y: scroll">
          <file-pond-input></file-pond-input>
        </div>
        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button size="sm" variant="warning" @click="addPhotos()" class="mr-1">Accept</b-button>
            <b-button size="sm" variant="warning" @click="cancelPhotos()" class="mr-1">Cancel</b-button>
          </b-col>
        </b-row>
      </div>

    </b-sidebar>
  </div>
</template>

<script>
// import {daysToMonth, getAppName} from "@/utils/utils";
// import Charts from "@/views/transactions/sidebars/Charts.vue";
// import {eventBus} from "@/main";
import * as utils from "../../utils/utils";
import router from "@/router";
import FilePondInput from "@/components/FilePondInput";
// import AddEcdis from "@/views/transactions/tabs/AddEcdis.vue";
import store from "@/store";
// import DevicesList from "@/views/transactions/tabs/DevicesList.vue";
// import EcdisList from "@/views/transactions/tabs/EcdisList.vue";
// import ShipsLists from "@/views/ships/ShipsLists.vue";
import {eventBus} from "@/main";

export default {
  name: "EcdisDetails",
  components: {FilePondInput},
  props: ["qr"],
  data() {
    return {
      audio: {
        is_recording: false,
        recorder: null,
        chunks: [],
        device: null,
        blobObj: null
      },

      audioInput: false,
      photoInput: false,
      // takePhoto: false,
      // isCameraOpen: false,
      // isPhotoTaken: false,
      // isShotPhoto: false,
      // isLoading: false,
      // link: '#'.link(
      // ),

      // stream: null,
      // video: null,
      // showSavePhoto: false,
      // imageDataUrl: null,
      // imgges: []
    }
  },
  computed: {
    utils() {
      return utils
    },
    is_busy() {
      return this.$store.state.fickle.orders_list.is_refresing
    },
    findInstallation() {
      let objIndex = this.$store.state.proto.installationsECDIS.findIndex(obj => obj.primarInfo && obj.primarInfo.primarPermit === this.qr.permit)
      return objIndex
    },
    visible_fields() {
      return [
        {key: 'id', label: 'ID', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'band', label: 'Band', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'created_date', label: 'Ordered', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'days_valid', label: 'Days valid', sortable: true, tdClass: 'small-black', thClass: 'small-gray'}
      ]
    }
  },
  mounted() {

    //this.audio.device = navigator.mediaDevices.getUserMedia({ audio: true });

    // let camera_button = document.querySelector("#start-camera");
    // let video = document.querySelector("#video");
    // let click_button = document.querySelector("#click-photo");
    // let canvas = document.querySelector("#canvas");
    //
    // camera_button.addEventListener('click', async function () {
    //   let stream = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
    //   video.srcObject = stream;
    // });
    //
    // click_button.addEventListener('click', function () {
    //   canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    //   let image_data_url = canvas.toDataURL('image/jpeg');
    //
    //   // data url of the image
    //   console.log(image_data_url);
    // });

  },
  methods: {
    recordAudio() {
      if (!this.audio.device)
        this.audio.device = navigator.mediaDevices.getUserMedia({ audio: true });
      this.audio.is_recording = true
      this.audio.device.then((stream) => {
        this.audio.recorder = new MediaRecorder(stream);
        this.audio.recorder.ondataavailable = (e) => {
          this.audio.chunks.push(e.data);
          if (this.audio.recorder.state === "inactive") {
            let blob = new Blob(this.audio.chunks, { type: "audio/wav" });
            // save to blobObj
            this.audio.blobObj = blob;
            this.audio.chunks = [];
            // emit to parent
            console.log("finished")
            const audioURL = window.URL.createObjectURL(this.audio.blobObj);
            console.log(audioURL)

            store.commit("fickle/setQRAudio", {blob: this.audio.blobObj, url: audioURL})

            this.audio.blobObj = null;
          }
        };
        // start
        this.audio.recorder.start();
      });
    },
    stopAudio() {
      // stop
      this.audio.is_recording = false
      this.audio.recorder.stop();
    },


    // callback(data) {
    //   console.log(data);
    // },
    // callback_done(data) {
    //   console.log(data);
    //   store.commit("fickle/setQRAudio", data)
    //   this.$refs.recorder.removeRecord(0);
    //   // this.$refs.recorder.removeRecord(1);
    //   // this.$refs.recorder._initRecorder()
    //   this.photoInput = false
    // },
    // getsrc() {
    //   // return "http://localhost:8080/example.mp3"
    //   return this.$store.state.fickle.qr.audio_src
    // },
    // camera() {
    //   this.takePhoto = true
    //   this.startCamera()
    // },
    //
    // async startCamera() {
    //   this.video = document.querySelector("#video");
    //   this.stream = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
    //   this.video.srcObject = this.stream;
    // },

    // stopCamera() {
    //   this.video.srcObject.getTracks().forEach(function (track) {
    //     track.stop()
    //   })
    //   this.showSavePhoto = false
    //   this.takePhoto = false
    //   this.video = null
    //   this.stream = null
    // },
    //
    // clickPhoto() {
    //   let video = document.querySelector("#video");
    //   let canvas = document.querySelector("#canvas");
    //   canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    //   this.imageDataUrl = canvas.toDataURL('image/jpeg');
    //   this.showSavePhoto = true
    // },

    // savePhoto() {
    //   this.showSavePhoto = false
    //   console.log(this.imageDataUrl);
    //
    //   this.imgges.push({
    //     src: this.imageDataUrl,
    //     thumbnail: null,
    //     width: 200,
    //     height: 200
    //   })
    //
    //   store.commit("fickle/setQRImages", this.imgges)
    //
    // },

    // toggleCamera() {
    //   if (this.isCameraOpen) {
    //     this.isCameraOpen = false;
    //     this.isPhotoTaken = false;
    //     this.isShotPhoto = false;
    //     this.stopCameraStream();
    //   } else {
    //     this.isCameraOpen = true;
    //     this.createCameraElement();
    //   }
    // },
    //
    // createCameraElement() {
    //   this.isLoading = true;
    //
    //   const constraints = (window.constraints = {
    //     audio: false,
    //     video: true
    //   });
    //
    //   navigator.mediaDevices
    //       .getUserMedia(constraints)
    //       .then(stream => {
    //         this.isLoading = false;
    //         this.$refs.camera.srcObject = stream;
    //       })
    //       .catch(error => {
    //         this.isLoading = false;
    //         alert("May the browser didn't support or there is some errors.");
    //       });
    // },
    //
    // stopCameraStream() {
    //   let tracks = this.$refs.camera.srcObject.getTracks();
    //
    //   tracks.forEach(track => {
    //     track.stop();
    //   });
    // },
    //
    // takePhoto() {
    //   if (!this.isPhotoTaken) {
    //     this.isShotPhoto = true;
    //
    //     const FLASH_TIMEOUT = 50;
    //
    //     setTimeout(() => {
    //       this.isShotPhoto = false;
    //     }, FLASH_TIMEOUT);
    //   }
    //
    //   this.isPhotoTaken = !this.isPhotoTaken;
    //
    //   const context = this.$refs.canvas.getContext('2d');
    //   context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    // },
    //
    // downloadImage() {
    //   const download = document.getElementById("downloadPhoto");
    //   const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
    //       .replace("image/jpeg", "image/octet-stream");
    //   download.setAttribute("href", canvas);
    // },

    addAudio() {
      this.audioInput = !this.audioInput;
    },
    cancelAudio() {
      this.audioInput = !this.audioInput;
    },
    addPhotos() {
      this.photoInput = !this.photoInput;
    },
    cancelPhotos() {
      this.photoInput = !this.photoInput;
    },

    showOnMap(payload) {
      store.commit("fickle/setSelectedPermit", this.qr.permit)
      router.push({name: 'reports'});
    },
    addECDIS() {

      if (this.$route.name !== 'ships') {
        router.push({name: 'ships', params: {qrevent: 'add-ecdis-by-qr'}})
        }
      else {
        eventBus.$emit('add-ecdis-by-qr-a')
      }
      // router.push({name: 'ships'})
      // alert(1)
      // setTimeout(() => {
      //   eventBus.$emit('add-ecdis-by-qr')
      // }, 500)
    },
    updateECDIS() {

      if (this.$route.name !== 'ships')
        router.push({name: 'ships', params: {qrevent: 'update-ecdis-by-qr'}})
      else
        eventBus.$emit('update-ecdis-by-qr')

      // alert(2)
      // router.push({name: 'ships'})
      // setTimeout(() => {
      //   eventBus.$emit('update-ecdis-by-qr')
      // }, 500)
    },
    visible_rows() {
      return this.$store.state.fickle.orders_list.cells
    }
  }
}
</script>

<style scoped>

</style>