import * as waypoints from "@/my-leaf/routes/components/route-waypoints";
import * as legs from "@/my-leaf/routes/components/route-legs"

export function createContextButtons() {
    this.map.on('click', (e) => {

        if (!this.isShowMapManu || this.isDrawNoGoZonesStarted)
            return

        let content
        let coordinates = e.latlng

        if (this.waypoints.length === 0) {
            let idStart = 'start-wp' + this.contextButtonsCount++;
            let idAdd = 'add-wp' + this.contextButtonsCount++;
            content = `<button id="` + idStart + `" class="button-13" onclick="">Start</button>&nbsp;<button id="` + idAdd + `" class="button-13" onclick="">Add</button>`
            L.popup({autoClose: false}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            document.getElementById(idStart).addEventListener('click', (e) => {
                this.map.closePopup()
                this.isBaypassMode = true
                waypoints.addWP.call(this, coordinates)
            })
            document.getElementById(idAdd).addEventListener('click', (e) => {
                this.map.closePopup()
                waypoints.addWP.call(this, coordinates)
                legs.drawLegs.call(this)
            })
        } else {
            let idFinish = 'finish-wp' + this.contextButtonsCount++;
            let idAdd = 'add-wp' + this.contextButtonsCount++;
            let idInsert = 'insert-wp' + this.contextButtonsCount++;
            let idMove = 'move-wp' + this.contextButtonsCount++;

            content = `<button id="` + idAdd + `" class="button-13" onclick="">Add</button>&nbsp;<button id="` + idInsert + `" class="button-13" onclick="">Insert</button>`

            if (this.waypoints.length > 0 && this.isBaypassMode)
                content = `<button id="` + idFinish + `" class="button-13" onclick="">Finish</button>
                        </button>`

            if (this.waypoints.length > 0 && this.selectedWaypointIndex !== undefined)
                content += `&nbsp;<button id="` + idMove + `" class="button-13" onclick="">Move</button>
                        </button>`

            L.popup({autoClose: false}).setLatLng(e.latlng).setContent(content).openOn(this.map);

            if (this.isBaypassMode) {
                document.getElementById(idFinish).addEventListener('click', (e) => {
                    this.map.closePopup()
                    waypoints.addWP.call(this, coordinates)
                    this.getAutoRoute()
                })
            } else {
                document.getElementById(idAdd).addEventListener('click', (e) => {
                    this.map.closePopup()
                    waypoints.addWP.call(this, coordinates)
                    legs.drawLegs.call(this)
                })
                document.getElementById(idInsert).addEventListener('click', (e) => {
                    this.map.closePopup()
                    legs.clearLegs.call(this)
                    waypoints.insertWP.call(this, coordinates)
                    legs.drawLegs.call(this)
                });

                if (this.waypoints.length > 0 && this.selectedWaypointIndex !== undefined) {
                    document.getElementById(idMove).addEventListener('click', (e) => {
                        this.map.closePopup()
                        this.changeWaypointCoordinates(this.selectedWaypointIndex, coordinates)
                        // legs.clearLegs.call(this)
                        // waypoints.insertWP.call(this, coordinates)
                        // legs.drawLegs.call(this)
                    });
                }
            }
        }
    })
}
