import {defineStore} from 'pinia'
import dayjs from "dayjs";

export const useMomentaryStore = defineStore({
    id: 'momentary',
    state: () => ({
        isBusy: false,
        isAPIError: false,
        isOrdersBusy: false,
        route: {
            chartsbyroute: [],
            chartsbylegs: [],
            pubsbylegs: []
        },
        tmp: [
            {ship: 'MSS-Ship', createdAt: '2024-11-12 19:12', type: 'Permit', order: '20572', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-11-12 19:20', type: 'Full', order: '20572', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-11-17 10:11', type: 'Update', order: '20572', link: ''},

            {ship: 'MSS-Ship', createdAt: '2024-10-04 11:03', type: 'Permit', order: '20412', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-10-04 11:08', type: 'Full', order: '20412', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-10-11 11:24', type: 'Update', order: '20412', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-10-18 09:07', type: 'Update', order: '20412', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-10-29 10:23', type: 'Update', order: '20412', link: ''},
            {ship: 'MSS-Ship', createdAt: '2024-11-07 10:12', type: 'Update', order: '20412', link: ''},
        ],
        tmpN: 28304
    }),
    actions: {
        setBusy(flag) {
            this.isBusy = flag
        },
        setAPIError(flag) {
            this.isAPIError = flag
        },
        setOrdersBusy(flag) {
            this.isOrdersBusy = flag
        },
        addChartsByRoute(val) {
            this.route.chartsbyroute = val
        },
        addChartsByLegs(val) {
            this.route.chartsbylegs = val
        },
        addPubsByLegs(val) {
            this.route.pubsbylegs = val
        },
        clearChartsByRoute() {
            this.route.chartsbyroute = []
        },
        clearChartsByLegs() {
            this.route.chartsbylegs = []
        },
        addTmp() {

            let indexPermit = this.tmp.length
            let indexFull = indexPermit + 1

            setTimeout(() => {
                let date = dayjs().format("YYYY-MM-DD HH:mm:ss")
                this.tmp.push({ship: 'MSS-Ship', createdAt: date, type: 'Permit', order: this.tmpN, link: 'waiting'})
            }, 2000);

            setTimeout(() => {
                let date = dayjs().format("YYYY-MM-DD HH:mm:ss")
                this.tmp.push({ship: 'MSS-Ship', createdAt: date, type: 'Full', order: this.tmpN, link: 'waiting'})
            }, 4000);

            setTimeout(() => {
                let date = dayjs().format("YYYY-MM-DD HH:mm:ss")
                this.tmp[indexPermit].link = ''
            }, 11000);

            setTimeout(() => {
                let date = dayjs().format("YYYY-MM-DD HH:mm:ss")
                this.tmp[indexFull].link = ''
            }, 12000);

            this.tmpN++
        }
        // addFull1() {
        //     this.tmp.push({ship: 'MSS-Ship', createdAt: '2024-11-20 12:00', type: 'Full', order: '10000', link: ''})
        // },
        // addPermit1() {
        //     this.tmp.push({ship: 'MSS-Ship', createdAt: '2024-11-20 12:00', type: 'Permit', order: '10000', link: ''})
        // }
    },
    persist: false
})
