import axios from 'axios';
import {authHeader} from '@/helpers/authHeader'
import router from "../router";
import axiosRetry from "axios-retry";
import store from "@/store";

const myAxios = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_BASE_URL : process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_BASE_URL,
    headers: {'Access-Control-Allow-Origin': '*'}
});
const myAxiosRetry = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_BASE_URL : process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_BASE_URL,
    headers: {'Access-Control-Allow-Origin': '*'}
});

axiosRetry(myAxiosRetry, { retries: 5, retryDelay: (retryCount) => { return retryCount * 1000; }, retryCondition: (_error) => _error.response.code !== 401} )

myAxios.interceptors.request.use(
    function (config) {
        config.headers.Authorization = '';
        let hdr = authHeader();
        if (hdr.Authorization) {
            config.headers.Authorization = hdr.Authorization;
        }

        store.commit("fickle/setCurrentRequest", config.url)

        console.log('Request URL:', JSON.stringify(config.url, null, 2))
        console.log('Request data:', JSON.stringify(config.data, null, 2))

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

myAxios.interceptors.response.use(response => {
    // console.log('Response data:', JSON.stringify(response, null, 2))
    return response
})



















myAxios.interceptors.response.use(
    function (response) {
        if (response.headers["newone"]) {
            let user = JSON.parse(localStorage.getItem("user"));
            user.token = response.headers["newone"];
            localStorage.setItem('user', JSON.stringify(user));
        }
        return response;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('user');
            router.push({name: "login"});
        }
        return Promise.reject(error);
    }
);
myAxiosRetry.interceptors.request.use(
    function (config) {
        config.headers.Authorization = '';
        let hdr = authHeader();
        if (hdr.Authorization) {
            config.headers.Authorization = hdr.Authorization;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);




















myAxiosRetry.interceptors.response.use(
    function (response) {
        if (response.headers["newone"]) {
            let user = JSON.parse(localStorage.getItem("user"));
            user.token = response.headers["newone"];
            localStorage.setItem('user', JSON.stringify(user));
        }
        return response;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('user');
            router.push({name: "login"});
        }
        return Promise.reject(error);
    }
);


export {myAxios, myAxiosRetry};