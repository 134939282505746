<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col>
            <b-form-select v-model="$parent.$parent.selected_ship" :options="$parent.$parent.ships" size="sm"></b-form-select>
          </b-col>
          <b-col class="text-left">
            <b-button variant="info" size="sm" @click="checkUpdates()">Check updates</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-table v-if="deviceStore.getDeliveriesCount > 0"
             ref="deliveriestable"
             class="secondary mt-2"
             fixed
             :busy="isBusy"
             :stacked="is_mobile"
             :items="items_ships()"
             :fields="visible_fields_ships"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             select-mode="single"
             selectable
             show-empty
             responsive
             sort-icon-left
             small
             hover
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>&nbsp;Loading...</strong>
        </div>
      </template>
      <template #cell(url)="row">
        <b-link :href="getDownloadLink(row.item.url)" class="smt17">Download</b-link>
      </template>

      <template #cell(timestamp)="row">
        {{ dayjs(row.item.timestamp).format("DD-MMM-YY HH:mm") }}
      </template>

      <template #cell(order)="row">
        #{{ row.item.order }}
      </template>

      <template #cell(size)="row">
        {{ niceBytes(row.item.size) }}
      </template>

      <template #cell(type)="row">
        <h6>
          <b-badge :variant=getStatus(row.item.type).style>{{ getStatus(row.item.type).name }}</b-badge>
        </h6>
      </template>

    </b-table>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {useUserStore} from "@/stores/user";
import {loadOrders} from "@/helpers/api";
import dayjs from "dayjs";
import {useDeviceStore} from "@/stores/device";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import router from "@/router";

export default {
  name: "Deliveries",
  data() {
    return {
      windowWidth: window.innerWidth,
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      filterOn: [],
    }
  },
  watch: {
    'selected_ship'(newVal) {
      loadOrders(newVal)
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    this.checkUpdates()
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    ...mapStores(useUserStore, useMomentaryStore, useDeviceStore),
    visible_fields_ships() {
      return [
        {
          key: 'ship', label: 'Ship', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'type', label: 'Type', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'timestamp', label: 'Date', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'size', label: 'Size', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'url', label: 'Download', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
      ]
    },

  },
  methods: {
    dayjs,
    getDownloadLink(url) {
      return url
    },
    niceBytes(x) {
      const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

      let l = 0, n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },
    checkUpdates() {
      if (!this.deviceStore.isDeviceIDExist)
        return

      this.isBusy = true;

      let data = {
        deviceIds: [this.deviceStore.getDeviceID],
        dateFrom: "2024-12-06T10:16:06.695Z"
        // dateFrom: dayjs().add(-1, "day")
      }
      let URL = "api/v1/deliveries"

      myAxiosRetry.post(URL, data,
          {
            headers: {
              'content-type': 'application/json',
            }
          }
      ).then((resp) => {
        let deliveries = []
        if (resp.data.deliveries.length > 0) {
          deliveries = resp.data.deliveries.filter(d => d.deviceId === this.deviceStore.getDeviceID)[0].deliveries
          deliveries.forEach(item => {
            item.ship = this.deviceStore.getShipName
          })
        }
        this.deviceStore.addDeliveries(deliveries)

        this.isBusy = false;
      })

    }
    ,
    items_ships() {
      let items = this.deviceStore.getDeliveries
      return items
    }
    ,
    tmp() {

      this.momentaryStore.addTmp()

      // setTimeout(function () {
      //   this.momentaryStore.addPermit1()
      // }, 1000)
      //
      // setTimeout(function () {
      //   this.momentaryStore.addFull1()
      // }, 3000)

    }
    ,
    items_orders() {
      // const items = [
      //   {ship: 'MSS-Ship', 'createdAt': '2024-11-12 12:24', type: 'Order 20572', link: ''},
      //   {ship: 'MSS-Ship', 'createdAt': '2024-11-12 12:24', type: 'Order 22308', link: ''},
      //   {ship: 'Another Ship', 'createdAt': '2024-10-12 10:12', type: 'Order 24210', link: ''},
      // ]

      if (this.selected_ship === '')
        return []

      let items = []
      // let ordersByDevice = this.userStore.ordersByDevices.filter(function (e) {
      //   return e["id"] === this.selected_ship;
      // })
      let ordersByDevice = this.userStore.ordersByDevices.filter(e => e.id === this.selected_ship)
      if (ordersByDevice.length === 0)
        return

      // let ordersByDevice = []

      console.log("aaaa1", ordersByDevice)

      ordersByDevice[0].orders.forEach(item => {
        console.log("aaaa2", item)
        items.push({ship: 'aaa', createdAt: item.createdAt, type: item.displayId, link: ''})
      })

      return items
    }
    ,
    getStatus(status) {
      let val = {}
      switch (status) {
        case 'Full':
          val = {style: "info", name: "full set"}
          break
        case 'Update':
          val = {style: "warning", name: "update (regular)"}
          break
        case 'Permit':
          val = {style: "success", name: "permits"}
          break
        default:
          val = {style: "warning", name: status}
      }
      return val
    }
    ,
  },
}
</script>

<style>
.position-absolute {
  position: fixed !important;
}
</style>


<style scoped>
.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>