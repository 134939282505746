<template>
  <div>
    <b-sidebar width="600px" id="ship-edit" shadow>

      <div class="px-3 py-2">
        <b-badge class="mb-2" variant="light">EDIT</b-badge>
        <div class="mb-2" align="right">
          <b-button class="mt-2" size="sm" type="button" @click="registerEcdis" :disabled="!(emailsNState && shipName && imoState && permitState)" variant="info">Save</b-button>&nbsp;
        </div>

        <b-card>
          <b-container>
            <b-form size="sm" @submit="registerEcdis" v-if="show">
<!--              <b-form-group size="sm" label="Delivery emails:" label-for="input-1">-->
<!--                <b-form-input size="sm" id="input-24" v-model="form.delivery_email" type="text" :state="emailsDState" placeholder="" required></b-form-input>-->
<!--              </b-form-group>-->
              <b-form-group size="sm" label="Notification emails:" label-for="input-1">
                <b-form-input size="sm" id="input-1" v-model="form.email" type="text" :state="emailsNState" placeholder="" required></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Ship name:" label-for="input-2">
                <b-form-input id="input-2" v-model="form.ship_name" type="text" placeholder="" :state="shipName" required></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="IMO:" label-for="input-3">
                <b-form-input id="input-3" v-model="form.ship_imo" type="text" :state="imoState" placeholder="IMO*******"></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="MMSI:" label-for="input-4">
                <b-form-input id="input-4" v-model="form.ship_mmsi" type="text" :state="imoState" placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Call Sign:" label-for="input-8">
                <b-form-input id="input-8" v-model="form.ship_callsign" type="text" :state="imoState" placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Permit:" label-for="input-9">
                <b-form-input :disabled=is_edit id="input-9" v-model="form.permit_main" type="text" :state="permitState" placeholder="" required></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Country:" label-for="input-14">
                <b-form-select id="input-14" v-model="form.country" :options="country" required></b-form-select>
              </b-form-group>
              <b-form-group size="sm" label="Length:" label-for="input-18">
                <b-form-input id="input-18" v-model="form.ship_l" type="number" placeholder="(optional)"></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Width:" label-for="input-19">
                <b-form-input id="input-19" v-model="form.ship_w" type="number" placeholder="(optional)"></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Height:" label-for="input-20">
                <b-form-input id="input-20" v-model="form.ship_h" type="number" placeholder="(optional)"></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Draught:" label-for="input-22">
                <b-form-input id="input-22" v-model="form.ship_d" type="number" placeholder="(optional)"></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Ship category:" label-for="input-5">
                <b-form-select id="input-5" v-model="form.ship_category" :options="categories"></b-form-select>
              </b-form-group>
              <b-form-group size="sm" label="ECDIS brand:" label-for="input-6">
                <b-form-select id="input-6" v-model="form.ecdis_brand" :options="brands"></b-form-select>
              </b-form-group>
              <b-form-group size="sm" label="ECIDS OS:" label-for="input-7">
                <b-form-select id="input-7" v-model="form.ecdis_os" :options="systems"></b-form-select>
              </b-form-group>
              <b-form-group size="sm" label="Backup permit 1:" label-for="input-10">
                <b-form-input id="input-10" v-model="form.permits_backup[0]" type="text" :state="permitState0" placeholder="(optional)"></b-form-input>
              </b-form-group>
              <b-form-group size="sm" label="Backup permit 2:" label-for="input-11">
                <b-form-input id="input-11" v-model="form.permits_backup[1]" type="text" :state="permitState1" placeholder="(optional)"></b-form-input>
              </b-form-group>
            </b-form>

<!--            <b-modal v-model="modalShow_ok" title="ECDIS registration" ok-only @ok="gotoMyDevices">-->
<!--              <p>-->
<!--                Now you can select the ECDIS device to purchase charts.-->
<!--              </p>-->
<!--            </b-modal>-->

          </b-container>
        </b-card>
        <br>

      </div>


    </b-sidebar>
  </div>
</template>

<script>

import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import router from "@/router";
import QRScanner from "@/components/qr/QREngine.vue";
import {registerECDIS} from "@/helpers/offline";

export default {
  components: {QRScanner},
  props: {
    value: Array,
    fields: Array
  },
  data() {
    return {
      is_edit: false,
      edit_item: null,
      form: {
        email: '',
        delivery_email: '',
        ship_name: '',
        ship_imo: '',
        ship_mmsi: '',
        ship_callsign: '',
        ship_category: '',

        ship_l: '',
        ship_w: '',
        ship_h: '',
        ship_d: '',

        country: '',

        ecdis_brand: '',
        ecdis_os: '',
        ecdis_type: '',

        permit_main: '',
        permits_backup: ['', ''],
      },
      categories: [{text: '(optional)', value: ''},
        'BRGE Barge',
        'WMS WMS',
        'CAR Vehicle Carrier',
        'COMB Combination',
        'CONT Container',
        'DRDG Dredger',
        'ICE Icebreaker',
        'NOT Not a Vessel',
        'NVY Naval vessel',
        'PASS Passenger Vessel',
        'PILOT Pilot',
        'RES Research Vessel',
        'RFR Reefer',
        'RORO Ro-Ro',
        'SLV Salvage Vessel',
        'SRV Survey Vessel',
        'TUG Tug',
        'VTS Vessel Traffic System',
        'CRG Dry Cargo/Passenger',
        'FSH Fishing',
        'BULK Bulk Carriers',
        'OFS Offshore',
        'OTH Miscellaneous',
        'TNK Tankers',
        'INLW Inland Watwerways',
        'NONM Non-Merchant Ships',
        'NONP Non Propelled',
        'NSS Non Ship Structures'],

      country: [{text: '', value: ''},
        'AL Albania',
        'DZ Algeria',
        'AO Angola',
        'AG Antigua and Barbuda',
        'AR Argentina',
        'AW Aruba',
        'AU Australia',
        'AT Austria',
        'BS Bahamas',
        'BH Bahrain',
        'BD Bangladesh',
        'BB Barbados',
        'BE Belgium',
        'B1 Belgium',
        'BZ Belize',
        'BJ Benin',
        'BM Bermuda',
        'BO Bolivia',
        'BR Brazil',
        'B2 Brunei Darussalam',
        'BN Brunei Darussalam',
        'BG Bulgaria',
        'KH Cambodia',
        'CM Cameroon',
        'CA Canada',
        'CV Cape Verde',
        'CL Chile',
        'C1 China C1',
        'C2 China C2',
        'CN China CN',
        'CO Colombia',
        'KM Comoros',
        'CD Congo (Dem. Rep. Of)',
        'CG Congo(Rep. of)',
        'CK Cook Islands',
        'CR Costa-Rica',
        'CI Côte d’Ivoire',
        'HR Croatia',
        'CU Cuba',
        'CY Cyprus',
        'CZ Czech Republic',
        'DK Denmark',
        'D1 Denmark',
        'DJ Djibouti',
        'DM Dominica',
        'DO Dominican Rep.',
        'EC Ecuador',
        'EG Egypt',
        'SV El Salvador',
        'GQ Equatorial Guinea',
        'ER Eritrea',
        'EE Estonia',
        'ET Ethiopia',
        'FO Faeroeyene',
        'FJ Fiji',
        'FI Finland',
        'FR France',
        'GA Gabon',
        'GM Gambia',
        'GE Georgia',
        'DE Germany',
        'GH Ghana',
        'GR Greece',
        'GD Grenada',
        'GT Guatemala',
        'G1 Guatemala',
        'GN Guinea',
        'GW Guinea-Bissau',
        'GY Guyana',
        'HT Haiti',
        'HN Honduras',
        'IS Iceland',
        'IN India',
        'ID Indonesia',
        'IR Iran',
        'IQ Iraq',
        'IE Ireland',
        'IL Israel',
        'IT Italy',
        'JM Jamaica',
        'JP Japan',
        'JO Jordan',
        'KE Kenya',
        'KI Kiribati',
        'KP Korea (DPR of)',
        'KR Korea (Rep. of)',
        'KW Kuwait',
        'LV Latvia',
        'LB Lebanon',
        'LR Liberia',
        'LY Libyan Arab Jamahiriya',
        'LT Lithuania',
        'LU Luxembourg',
        'MG Madagascar',
        'MW Malawi',
        'MY Malaysia',
        'MV Maldives',
        'MT Malta',
        'MH Marshall Islands',
        'MR Mauritania',
        'MU Mauritius',
        'MX Mexico',
        'FM Micronesia(Federated States of)',
        'MC Monaco',
        'MN Mongolia',
        'ME Montenegro',
        'MA Morocco ',
        'MZ Mozambique',
        'MM Myanmar',
        'NA Namibia',
        'NR Nauru',
        'NL Netherlands',
        'NZ New Zealand',
        'NI Nicaragua',
        'NG Nigeria',
        'NU Niue ',
        'NO Norway',
        'N1 Norway',
        'OM Oman',
        'PK Pakistan',
        'PW Palau',
        'PA Panama',
        'PG Papua New Guinea',
        'PY Paraguay',
        'PE Peru',
        'PH Philippines',
        'PL Poland',
        'PT Portugal',
        'QA Qatar',
        'RO Romania ',
        'RU Russian Federation',
        'KN Saint Kitts and Nevis',
        'LC Saint Lucia',
        'VC Saint Vincent and the Grenadines',
        'WS Samoa',
        'ST Sao Tome and Principe',
        'SA Saudi Arabia',
        'SN Senegal',
        'SC Seychelles',
        'SL Sierra Leone',
        'SG Singapore',
        'SI Slovenia',
        'SB Solomon Islands',
        'SO Somalia',
        'ZA South Africa(Rep. of)',
        'ES Spain',
        'LK Sri Lanka',
        'SD Sudan',
        'SR Suriname',
        'SE Sweden',
        'CH Switzerland',
        'SY Syrian Arab Republic',
        '1U Taiwan',
        'TZ Tanzania',
        'TH Thailand',
        'TG Togo',
        'TK Tokelau',
        'TO Tonga',
        'TT Trinidad&Tobago',
        'TN Tunisia',
        'TR Turkey',
        'TV Tuvalu',
        'UA Ukraine',
        'AE United Arab Emirates',
        'GB United Kingdom',
        'NN Unknown',
        'UY Uruguay',
        'U1 USA',
        'U2 USA',
        'US USA',
        'VU Vanuatu',
        'VE Venezuela',
        'VN Viet Nam',
        'YE Yemen',
        'YU Yugoslavia',
        'ZZ Zanzibar'],

      brands: [{text: '(optional)', value: ''},
        'dKart',
        'FURUNO',
        'GEM Elettronica',
        'GMSTECH',
        'Highlander',
        'Kelvin Hughes',
        'Kongsberg Maritime',
        'Maris',
        'mKart MEGA ECDIS',
        'NAUDEQ',
        'Navis USA LLC',
        'NaviSailor',
        'PC Maritime',
        'Raytheon Anschütz',
        'Seatronx',
        'Sodena',
        'Sperry Marine',
        'TOKIO KEIKI',
        'Transas',
        'JRC',
        'Unknown'],

      systems: [{text: '(optional)', value: ''},
        'Windows',
        'Linux',
        'Android',],

      types: [{text: '(optional)', value: ''},
        'Main',
        'Backup',],

      show: true,

      // modalShow_ok: false,
      // modalShow_nok: false,
    }
  },
  mounted() {

    eventBus.$off('add-ecdis-by-qr')
    eventBus.$on('add-ecdis-by-qr', () => {
      let shipInfo = this.$store.state.fickle.qr.shipInfo
      this.form.ship_name = shipInfo.name
      this.form.ship_mmsi = shipInfo.MMSI
      this.form.ship_imo = shipInfo.IMO
      this.form.permit_main = shipInfo.permit
      this.form.ecdis_os = shipInfo.OS
      this.form.ecdis_brand = shipInfo.ECDIS

      this.form.ship_l = shipInfo.length
      this.form.ship_w = shipInfo.width
      this.form.ship_h = shipInfo.height
      this.form.ship_d = shipInfo.draught
    })

    eventBus.$off('add-ecdis-by-qr-1')
    eventBus.$on('add-ecdis-by-qr-1', () => {
      let shipInfo = this.$store.state.fickle.qr.shipInfo
      this.form.ship_name = shipInfo.name
      this.form.ship_imo = shipInfo.IMO === 0 ? "" : shipInfo.IMO
      this.form.ship_mmsi = shipInfo.MMSI.toString()
      this.form.permit_main = shipInfo.permit
      this.form.ecdis_os = shipInfo.OS
      this.form.ecdis_brand = shipInfo.ECDIS

      this.form.ship_l = shipInfo.length
      this.form.ship_w = shipInfo.width
      this.form.ship_h = shipInfo.height
      this.form.ship_d = shipInfo.draught
    })

    eventBus.$off('update-ecdis-by-qr-1')
    eventBus.$on('update-ecdis-by-qr-1', () => {
      this.is_edit = true

      let shipInfo = this.$store.state.fickle.qr.shipInfo
      let objIndex = this.$store.state.proto.installationsECDIS.findIndex(obj => obj.primarInfo && obj.primarInfo.primarPermit === shipInfo.permit)

      let item = this.$store.state.proto.installationsECDIS[objIndex]
      this.edit_item = item

      this.form.email = item.primarInfo.notificationEmails

      this.form.ship_name = item.primarInfo.shipInfo.name
      this.form.ship_mmsi = item.primarInfo.shipInfo.mmsi
      this.form.ship_imo = item.primarInfo.shipInfo.imo

      this.form.permit_main = item.primarInfo.primarPermit
      this.form.ecdis_os = item.primarInfo.shipInfo.ecdisOs
      this.form.ecdis_brand = item.primarInfo.shipInfo.ecdisBrand
      this.form.country = item.primarInfo.shipInfo.flag
      this.form.ship_callsign = item.primarInfo.shipInfo.callSign
      this.form.ship_category = item.primarInfo.shipInfo.category
      this.form.permits_backup[0] = item.primarInfo.backupPermits[0]
      this.form.permits_backup[1] = item.primarInfo.backupPermits[1]


      this.form.ship_name = shipInfo.name
      this.form.ship_mmsi = shipInfo.MMSI
      this.form.ship_imo = shipInfo.IMO
      this.form.permit_main = shipInfo.permit
      this.form.ecdis_os = shipInfo.OS
      this.form.ecdis_brand = shipInfo.ECDIS

      this.form.ship_l = shipInfo.length
      this.form.ship_w = shipInfo.width
      this.form.ship_h = shipInfo.height
      this.form.ship_d = shipInfo.draught
    })

    eventBus.$off('update-ecdis-by-qr')
    eventBus.$on('update-ecdis-by-qr', () => {
      this.is_edit = true

      let shipInfo = this.$store.state.fickle.qr.shipInfo
      let objIndex = this.$store.state.proto.installationsECDIS.findIndex(obj => obj.primarInfo && obj.primarInfo.primarPermit === shipInfo.permit)

      let item = this.$store.state.proto.installationsECDIS[objIndex]
      this.edit_item = item

      this.form.email = item.primarInfo.notificationEmails

      this.form.ship_name = item.primarInfo.shipInfo.name
      this.form.ship_mmsi = item.primarInfo.shipInfo.mmsi
      this.form.ship_imo = item.primarInfo.shipInfo.imo

      this.form.permit_main = item.primarInfo.primarPermit
      this.form.ecdis_os = item.primarInfo.shipInfo.ecdisOs
      this.form.ecdis_brand = item.primarInfo.shipInfo.ecdisBrand
      this.form.country = item.primarInfo.shipInfo.flag
      this.form.ship_callsign = item.primarInfo.shipInfo.callSign
      this.form.ship_category = item.primarInfo.shipInfo.category
      this.form.permits_backup[0] = item.primarInfo.backupPermits[0]
      this.form.permits_backup[1] = item.primarInfo.backupPermits[1]


      this.form.ship_name = shipInfo.name
      this.form.ship_mmsi = shipInfo.MMSI
      this.form.ship_imo = shipInfo.IMO
      this.form.permit_main = shipInfo.permit
      this.form.ecdis_os = shipInfo.OS
      this.form.ecdis_brand = shipInfo.ECDIS

      this.form.ship_l = shipInfo.length
      this.form.ship_w = shipInfo.width
      this.form.ship_h = shipInfo.height
      this.form.ship_d = shipInfo.draught
    })

    eventBus.$off('edit-ecdis')
    eventBus.$on('edit-ecdis', (item) => {
      this.is_edit = true
      this.edit_item = item

      this.form.email = item.primarInfo.notificationEmails

      this.form.ship_name = item.primarInfo.shipInfo.name
      this.form.ship_mmsi = item.primarInfo.shipInfo.mmsi
      this.form.ship_imo = item.primarInfo.shipInfo.imo

      this.form.permit_main = item.primarInfo.primarPermit
      this.form.ecdis_os = item.primarInfo.shipInfo.ecdisOs
      this.form.ecdis_brand = item.primarInfo.shipInfo.ecdisBrand
      this.form.country = item.primarInfo.shipInfo.flag
      this.form.ship_callsign = item.primarInfo.shipInfo.callSign
      this.form.ship_category = item.primarInfo.shipInfo.category
      this.form.permits_backup[0] = item.primarInfo.backupPermits[0]
      this.form.permits_backup[1] = item.primarInfo.backupPermits[1]
    })

    eventBus.$off('clean-ecdis')
    eventBus.$on('clean-ecdis', () => {
      this.is_edit = false
      this.edit_item = null

      this.form.email = ''

      this.form.ship_name = ''
      this.form.ship_mmsi = ''
      this.form.ship_imo = ''

      this.form.permit_main = ''
      this.form.ecdis_os = ''
      this.form.ecdis_brand = ''
      this.form.country = ''
      this.form.ship_callsign = ''
      this.form.ship_category = ''
      this.form.permits_backup[0] = ''
      this.form.permits_backup[1] = ''
    })

  },
  computed: {
    emailsDState() {
      return !this.form.delivery_email.includes(" ") && this.form.delivery_email.includes("@") && this.form.delivery_email.includes(".");
    },
    emailsNState() {
      return !this.form.email.includes(" ") && this.form.email.includes("@") && this.form.email.includes(".");
    },
    deliveryEmailsState() {
      return !this.form.delivery_email.includes(" ") && this.form.delivery_email.includes("@");
    },
    shipName() {
      return this.form.ship_name.length > 1;
    },
    permitState() {

      if (this.form.permit_main.length !== 28)
        return false

      let crc32=function(r){for(var a,o=[],c=0;c<256;c++){a=c;for(var f=0;f<8;f++)a=1&a?3988292384^a>>>1:a>>>1;o[c]=a}for(var n=-1,t=0;t<r.length;t++)n=n>>>8^o[255&(n^r.charCodeAt(t))];return(-1^n)>>>0};
      let code = this.form.permit_main.slice(0, 16)
      let crc = this.form.permit_main.slice(16, 24)

      let check = crc32(code).toString(16).toUpperCase()
      check = ('0000000'+check).slice(-8);

      if (crc === check)
        return true
      else
        return false
    },
    permitState0() {
      if (this.form.permits_backup[0].length === 0)
        return true

      var crc32=function(r){for(var a,o=[],c=0;c<256;c++){a=c;for(var f=0;f<8;f++)a=1&a?3988292384^a>>>1:a>>>1;o[c]=a}for(var n=-1,t=0;t<r.length;t++)n=n>>>8^o[255&(n^r.charCodeAt(t))];return(-1^n)>>>0};
      let code = this.form.permits_backup[0].slice(0, 16)
      let crc = this.form.permits_backup[0].slice(16, 24)
      let check = crc32(code).toString(16).toUpperCase()
      if (crc === check)
        return true
      else
        return false
    },
    permitState1() {
      if (this.form.permits_backup[1].length === 0)
        return true

      var crc32=function(r){for(var a,o=[],c=0;c<256;c++){a=c;for(var f=0;f<8;f++)a=1&a?3988292384^a>>>1:a>>>1;o[c]=a}for(var n=-1,t=0;t<r.length;t++)n=n>>>8^o[255&(n^r.charCodeAt(t))];return(-1^n)>>>0};
      let code = this.form.permits_backup[1].slice(0, 16)
      let crc = this.form.permits_backup[1].slice(16, 24)
      let check = crc32(code).toString(16).toUpperCase()
      if (crc === check)
        return true
      else
        return false
    },
    imoState() {
      if (this.form.ship_imo.length > 0)
        return this.form.ship_imo.length === 10 && this.form.ship_imo.startsWith("IMO")
      else
        return this.form.ship_mmsi.length === 9 || this.form.ship_callsign.length > 2
    },
    callsignState() {
      return this.form.ship_callsign.length <= 10;
    },
    mmsiState() {
      return this.form.ship_mmsi.length < 20;
    }
  },
  methods: {
    toggleQR() {
      this.$root.$emit('bv::toggle::collapse', 'qr-sidebar');
    },
    gotoMyDevices() {
      // router.push({name: 'ships'});
      let y = setTimeout(() => {
        window.location.reload();
      }, 1000)
    },
    registerEcdis() {

      let userID = this.$store.state.current.profile.user_id;
      let deviceID = this.$store.state.current.profile.device_id;
      if (this.edit_item)
        deviceID = this.edit_item.deviceId
      // let URL = "/api/v1/ecdis/register";

      let data = {
        userPermit: this.form.permit_main,
        notificationEmails: this.form.email,
        deliveryEmail: this.form.delivery_email,
        backupUserPermits: this.form.permits_backup,
        shipInfo: {
          name: this.form.ship_name,
          imo: this.form.ship_imo,
          mmsi: this.form.ship_mmsi,
          callSign: this.form.ship_callsign,
          category: this.form.ship_category,
          ecdisBrand: this.form.ecdis_brand,
          ecdisOs: this.form.ecdis_os,
          flag: this.form.country
        },
      }

      if (this.is_edit) {
        // URL = "/api/v1/ecdis/update"
        data["deviceId"] = deviceID;
      }

      this.$root.$emit('bv::toggle::collapse', 'ship-edit');
      registerECDIS(this.is_edit, data)


      // this.$store.dispatch('proto/addEcdis', data).then(resp => {
      //   if (resp) {
      //     // alert("ok")
      //   }
      // })

      // myAxios.post(URL, data).then(resp => {
      //   console.log(resp.status)
      //   this.$root.$emit('bv::toggle::collapse', 'ship-edit');
      //   if (resp.status === 200) {
      //     this.$store.commit('proto/clearInstallations')
      //     this.$store.dispatch('proto/getInstallations').then(() => {
      //     });
      //
      //     // this.modalShow_ok = true;
      //   } else {
      //     // this.modalShow_nok = true;
      //     console.log("TODO: error")
      //   }
      //
      // })
      return false
    },
  }
}
</script>