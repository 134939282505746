import {RouteLeafControls} from "@/my-leaf/routes/route-leaf-controls";
import L from "leaflet";
import {eventBus, routes_map} from "@/main";
import store from "@/store";
import GeometryUtil from "leaflet-geometryutil";
import * as legs from "@/my-leaf/routes/components/route-legs";
import * as waypoints from "@/my-leaf/routes/components/route-waypoints";
import dayjs from "dayjs";
import {myAxios} from "@/services/myAxios";
import {getWeather} from "@/my-leaf/routes/components/route-utils";

let objectsPolyline = L.layerGroup();
let pointsLayer = L.featureGroup();

export class RouteLeafDraw extends RouteLeafControls {
    wpGroup = null
    legsGroup = null
    wpGeneralRouteGroup = null
    legsGeneralRouteGroup = null
    bypassGroup = null
    dangerMarkersGroup = null
    contextButtonsCount = 0
    logbookGroup = null
    // nogoZoneGroup = null

    drawControl = null
    nogoList = null

    async initbasewp(el) {
        super.initbasecontrols(el);

        this.dangerMarkersGroup = L.layerGroup();
        this.map.addLayer(this.dangerMarkersGroup);

        this.bypassGroup = L.layerGroup();
        this.map.addLayer(this.bypassGroup);

        this.legsGeneralRouteGroup = L.layerGroup();
        this.map.addLayer(this.legsGeneralRouteGroup);

        this.wpGeneralRouteGroup = L.layerGroup();
        this.map.addLayer(this.wpGeneralRouteGroup);

        this.legsGroup = L.layerGroup();
        this.map.addLayer(this.legsGroup);

        this.wpGroup = L.layerGroup();
        this.map.addLayer(this.wpGroup);

        this.logbookGroup = L.layerGroup();
        this.map.addLayer(this.logbookGroup);

        // this.nogoZoneGroup = L.layerGroup();
        // this.map.addLayer(this.nogoZoneGroup);

        this.nogoList = new L.FeatureGroup();
        this.map.addLayer(this.nogoList);

        this.map.addLayer(pointsLayer);
        this.map.addLayer(objectsPolyline);

        let options = {
            position: 'topleft',
            draw: {
                polyline: false,
                circle: false,
                polygon: false,
                marker: false,
                rectangle: {shapeOptions: {color: '#f357a1', weight: 1}},
                circlemarker: false
            },
            edit: {
                featureGroup: this.nogoList,
                remove: true
            }
        };

        this.drawControl = new L.Control.Draw(options)
        this.map.on('draw:created', (e) => {
            let type = e.layerType,
                layer = e.layer;

            console.log("draw created")
            this.nogoList.addLayer(layer);
        });

        // eventBus.$on('move-left', () => {
        //     let scale = L.control.scale()
        //     let meters = scale._getRoundNum(this.map.containerPointToLatLng([0, this.map.getSize().y / 2]).distanceTo(this.map.containerPointToLatLng([scale.options.maxWidth, this.map.getSize().y / 2])))
        //     let radius = meters / 5
        //     let coordinates = GeometryUtil.destination(this.waypoints[this.selectedWaypointIndex].getLatLng(), 270, radius);
        //     this.waypoints[this.selectedWaypointIndex].setLatLng(coordinates)
        //     legs.clearLegs.call(this)
        //     legs.drawLegs.call(this)
        //     this.map.closePopup()
        //
        //     this.map.panTo(this.waypoints[this.selectedWaypointIndex].getLatLng())
        // })

        // eventBus.$off('toggle-nogo-edit-mode')
        // eventBus.$on('toggle-nogo-edit-mode', state => {
        //     this.nogoZoneGroup.clearLayers()
        //
        //     // if (state) {
        //     //     console.log("ggg", nogoList)
        //     // }
        //
        //
        //     if (state) {
        //         this.map.removeControl(this.drawControl)
        //         isDrawNoGoZonesStarted = false
        //
        //         // this.map.removeControl(this.wpControl)
        //         // this.clearControl.addTo(this.map)
        //         // this.safetyControl.addTo(this.map)
        //         // this.safetyWPControl.addTo(this.map)
        //         // this.recalculateControl.addTo(this.map)
        //         // this.recalculateWPControl.addTo(this.map)
        //         // this.autorouteControl.addTo(this.map)
        //         // this.wpControl.addTo(this.map)
        //
        //         // if (this.is_autorouting) {
        //         //     eventBus.$emit('toggle-mode-after-edit-zones')
        //         // }
        //
        //     } else {
        //         this.drawControl.addTo(this.map);
        //         isDrawNoGoZonesStarted = true
        //
        //         // this.map.removeControl(this.autorouteControl)
        //         // this.map.removeControl(this.clearControl)
        //         // this.map.removeControl(this.safetyControl)
        //         // this.map.removeControl(this.safetyWPControl)
        //         // this.map.removeControl(this.recalculateControl)
        //         // this.map.removeControl(this.recalculateWPControl)
        //         // this.map.removeControl(this.wpControl)
        //         // this.map.removeControl(this.moveControl)
        //     }
        // })


        eventBus.$off('show-dangers-in-wp')
        eventBus.$on('show-dangers-in-wp', (id) => {
            this.dangerMarkersGroup.clearLayers()
            if (id !== -1) {
                this.routesStore.getLegHazards(id).forEach(obj => {
                    if (obj.isDanger) {
                        console.log("aaaa", obj)
                        this.setDangerMarkers(obj.points, 'danger');
                    }
                })
            }
        })

        // eventBus.$off("draw-object")
        // eventBus.$on("draw-object", (payload) => {
        //
        //     let lines = payload.lines
        //     let type = payload.type
        //     let needZoom = payload.zoom
        //
        //     if (type ===  "point") {
        //         let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
        //         this.map.addLayer(pointsLayer)
        //         objectCircle.addTo(pointsLayer);
        //     }
        //
        //     objectsPolyline.clearLayers()
        //
        //     if (type === 'line') {
        //         let object = new L.Polyline(lines, {
        //             color: '#5e00ff',
        //             weight: 3,
        //             opacity: 1,
        //             smoothFactor: 1
        //         });
        //         object.addTo(objectsPolyline)
        //
        //     } else {
        //         let object = new L.Polygon(lines, {
        //             color: '#5e00ff',
        //             weight: 3,
        //             opacity: 1,
        //             smoothFactor: 1
        //         });
        //         object.addTo(objectsPolyline)
        //     }
        //
        //     if (needZoom) {
        //         let allCoordinates = []
        //         lines.forEach(line => {
        //             line.forEach(point => {
        //                 allCoordinates.push([point.lat, point.lon])
        //             })
        //         })
        //
        //         let p
        //         if (type === 'line') {
        //             p = L.polyline(allCoordinates)
        //         } else {
        //             p = L.polygon(allCoordinates)
        //         }
        //
        //         let b = p.getBounds()
        //         this.map.fitBounds(b)
        //     }
        //
        // })

        this.map.on('zoomend', () => {
            const zoom = this.map.getZoom();

            this.map.removeLayer(this.dangerMarkersGroup);
            // this.map.removeLayer(this.warningMarkers);

            // if (this.isZonesTab) {
            //     this.map.removeLayer(allObjectsPolyline)
            //     this.map.removeLayer(objectsPolyline)
            // }
            //
            // if (zoom >= 8 && this.isZonesTab) {
            //     this.map.addLayer(allObjectsPolyline)
            //     this.map.addLayer(objectsPolyline)
            // }

            if (zoom >= 14) {
                this.map.addLayer(this.dangerMarkersGroup)
                // this.map.addLayer(this.warningMarkers)
            }
        })

        this.drawLogbookState()
    }

    showDangersInWaypoint(index) {
        this.dangerMarkers.clearLayers()
        if (index !== -1) {
            store.state.routes.dangers.forEach(obj => {
                if (obj.isDanger && (index === obj.legIndex || index === obj.legIndex - 1 || index === obj.legIndex + 1))
                    this.setDangerMarkers(obj.points, 'danger');
            })
        }
    }

    clearDangerObject() {
        objectsPolyline.clearLayers()
        pointsLayer.clearLayers()
    }

    drawDangerObject(payload) {
        let lines = payload.lines
        let type = payload.type
        let needZoom = payload.zoom

        if (type === "point") {
            let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
            this.map.addLayer(pointsLayer)
            objectCircle.addTo(pointsLayer);
        }

        objectsPolyline.clearLayers()

        if (type === 'line') {
            let object = new L.Polyline(lines, {
                color: '#5e00ff',
                weight: 3,
                opacity: 1,
                smoothFactor: 1
            });
            object.addTo(objectsPolyline)

        } else {
            let object = new L.Polygon(lines, {
                color: '#5e00ff',
                weight: 3,
                opacity: 1,
                smoothFactor: 1
            });
            object.addTo(objectsPolyline)
        }

        if (needZoom) {
            let allCoordinates = []
            lines.forEach(line => {
                line.forEach(point => {
                    allCoordinates.push([point.lat, point.lon])
                })
            })

            let p
            if (type === 'line') {
                p = L.polyline(allCoordinates)
            } else {
                p = L.polygon(allCoordinates)
            }

            let b = p.getBounds()
            this.map.fitBounds(b)
        }
    }

    setDangerMarkers = (points, type) => {

        // let icon = type == "danger" ? "<div style='font-size: 20px'>&#128315;</div>" : "<div class='yellow'>&#128315;</div>"
        // let icon = type == "danger" ? "<div style='color: #e5d60f'>&#128315;</div>" : "<img src='./marker-warning.png'/>"
        // let markerDanger = L.divIcon({
        //     className: '',
        //     iconSize: [20, 20],
        //     iconAnchor: [10, 10],
        //     html: icon   // best results if iconSize = font-size = line-height and iconAnchor font-size/2 .both values needed to position symbol in center of L.divIcon for all font-sizes.
        // });

        // let markerDanger = new L.circleMarker(coordinates, {
        //     bubblingMouseEvents: false,
        //     autoPan: true,
        //     radius: 4,
        //     weight: 15,
        //     opacity: 0,
        //     fillOpacity: 0.8,
        //     color: 'red',
        //
        // }).addTo(this.dangerMarkersGroup);

        points.forEach(pt => {

            let markerDanger = new L.circleMarker([pt.lat, pt.lon], {
                bubblingMouseEvents: false,
                autoPan: true,
                radius: 8,
                weight: 1,
                color: 'red',

            }).addTo(this.dangerMarkersGroup);

            // let marker = L.marker([pt.lat, pt.lon], {icon: markerDanger, interactive: false});
            // marker.addTo(this.dangerMarkersGroup);
        })
    }

    addNoGoLayer() {
        if (this.navalLayer) this.navalLayer.remove();
        const draught = this.routesStore.getDraught;

        const url = "https://nogotiles.bgeo.fi:6007/nogo/{z}/{x}/{y}?depth=" + draught;
        console.log(url);
        this.map.setMinZoom(2)
        this.navalLayer = L.tileLayer(url, {
            minZoom: 8,
            maxZoom: 20,
        }).addTo(this.map)
    }

    clearRoute(clearGeneralRoute = true, clearBypassBackup = true, clearStore = true) {
        this.wpGroup.clearLayers()
        this.legsGroup.clearLayers()
        this.wpGeneralRouteGroup.clearLayers()
        this.bypassGroup.clearLayers()
        this.dangerMarkersGroup.clearLayers()
        objectsPolyline.clearLayers()
        pointsLayer.clearLayers()
        this.waypoints = []
        this.isBaypassMode = false
        this.selecteBaypassWaypoints = []
        this.isAutoroutingCancel = false
        this.selectedWaypointIndex = undefined
        this.contextButtonsCount = 0

        if (clearGeneralRoute) {
            this.legsGeneralRouteGroup.clearLayers()
        }

        if (clearStore)
            this.routesStore.reset()

        if (clearBypassBackup)
            this.routesStore.resetBypassBackup()

        eventBus.$emit('clear-order')

        this.refreshControls()
    }

    clearBypass() {
        this.bypassGroup.clearLayers()
        this.selecteBaypassWaypoints = []
        waypoints.refreshWaypointsTooltips.call(this)
    }

    restoreSelectedWaypointIndex() {
        if (this.waypoints.length > 0) {
            let index = this.selectedWaypointIndex
            this.selectWaypoint(index)
        }
    }

    refreshSelectedWaypointIndex() {
        if (this.waypoints.length > 0) {
            let newIndex = this.selectedWaypointIndex > 0 ? this.selectedWaypointIndex - 1 : 0;
            this.selectWaypoint(newIndex)
        }
    }

    changeSelectedWaypoint(direction) {
        if (direction == "left") {
            if (this.selectedWaypointIndex - 1 >= 0) {
                let newWaypointIndex = this.selectedWaypointIndex - 1
                this.selectWaypoint(newWaypointIndex)
                eventBus.$emit('move-prev')
            }
        } else {
            if (this.selectedWaypointIndex + 1 < this.waypoints.length) {
                let newWaypointIndex = this.selectedWaypointIndex + 1
                this.selectWaypoint(newWaypointIndex)
                eventBus.$emit('move-next')
            }
        }
    }

    dateu(seconds) {
        let date = this.routesStore.getStartDate
        let time = this.routesStore.getStartTime
        let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
        let datetimenew = datetime.add(seconds, "seconds")
        let shortdate = datetimenew.unix()

        return shortdate
    }

    zoomToWaypoint(id, maxZoom, showWeather = false) {
        const waypoint = this.waypoints[id];

        let prevZoom = this.map.getZoom()
        let newZoom = prevZoom > maxZoom ? prevZoom : maxZoom

        this.map.setView([waypoint.getLatLng().lat, waypoint.getLatLng().lng], newZoom, {animate: false})

        if (showWeather) {
            let time = this.routesStore.getStartDate + ' ' + this.routesStore.getStartTime
            let datetime = this.dateu(this.routesStore.getLegInfo(id).fromStartSeconds)
            getWeather.call(this, waypoint.getLatLng(), datetime)
        }

    }

    selectWaypoint(id) {
        this.selectedWaypointIndex = id

        this.refreshControls()

        this.zoomToWaypoint(id, 14)

        // let prevZoom = this.map.getZoom()
        // let newZoom = prevZoom > 14 ? prevZoom : 14
        //
        // this.map.setView([waypoint.getLatLng().lat, waypoint.getLatLng().lng], newZoom, {animate: false})
        eventBus.$emit('wp-control-select-wp', id)

        this.waypoints.forEach(waypoint => {
            let color = waypoints.getWaypointColor.call(this, waypoint.options.id)
            if (id === waypoint.options.id) {
                waypoint.options.isSelected = true
                waypoint.setStyle({color: 'white'});

            } else {
                waypoint.options.isSelected = false
                waypoint.setStyle({color: color});
            }
        })
    }

    changeWaypointCoordinates(index, coordinates, needPan = true) {
        this.waypoints[index].setLatLng(coordinates)
        legs.clearLegs.call(this)
        legs.drawLegs.call(this)
        this.map.closePopup()

        if (needPan)
            this.map.panTo(this.waypoints[index].getLatLng())
    }

    moveWaypoint(direction, waypointIndex = -1, needPan = true) {
        if (waypointIndex === -1)
            waypointIndex = this.selectedWaypointIndex

        let scale = L.control.scale()
        let meters = scale._getRoundNum(this.map.containerPointToLatLng([0, this.map.getSize().y / 2]).distanceTo(this.map.containerPointToLatLng([scale.options.maxWidth, this.map.getSize().y / 2])))
        let radius = meters / 5
        let coordinates = GeometryUtil.destination(this.waypoints[waypointIndex].getLatLng(), direction, radius);
        this.changeWaypointCoordinates(waypointIndex, coordinates, needPan)

        // eventBus.$emit('wp-control-toggle-check-safety', true)
    }

    moveLeg(direction) {
        let wp1 = this.selectedWaypointIndex
        let wp2 = this.selectedWaypointIndex + 1

        if (wp2 < this.waypoints.length) {
            this.moveWaypoint(direction, wp2, false)
        }
        this.moveWaypoint(direction, wp1)

        // eventBus.$emit('wp-control-toggle-check-safety', true)
    }

    deleteWaypoint() {
        if (this.selectedWaypointIndex > 0) {
            eventBus.$emit('move-prev');
        }

        waypoints.removeWP.call(this, this.selectedWaypointIndex)
    }

    drawStartFinish(coordinates, type) {
        let text = type === 0 ? "<div style=\'color:#16ba16;\'>Start</div>" : "<div style='color:#eda405;'>Finish</div>"
        waypoints.drawFromToPoints.call(this, coordinates, text)
    }

    drawBaypassStartFinish(coordinates, type) {
        let text = this.selecteBaypassWaypoints.length === 1 ? 'Start bypass' : 'Finish bypass'
        waypoints.drawBaypassPoints.call(this, coordinates, text)
    }

    drawGeneralRoute() {
        // this.map.removeLayer(this.wpGeneralRouteGroup)
        // this.wpGlobalMarkers.forEach(wp => {
        //     wp.remove()
        // })
        // this.wpGlobalMarkers = []
        // this.map.addLayer(this.wpGeneralRouteGroup);

        let globalRoute = []
        this.routesStore.routeGeneral.forEach(wp => {
            // this.addGlobalWP({lat: wp.lat, lng: wp.lon})
            globalRoute.push([wp.lat, wp.lon])
        })

        let routeLine = L.polyline(globalRoute, {
            color: 'blue',
            weight: 2,
            dashArray: '5, 5',
            dashOffset: '0'
        }).addTo(this.legsGeneralRouteGroup);
        // routeLine.bringToBack()

        let bounds = routeLine.getBounds()
        this.map.fitBounds(bounds);
    }

    panToCoordinates(coordinates, zoom = 8) {
        this.map.setView([coordinates.lat, coordinates.lon], 8);
    }

    setTileLayerWithWeather(type, unixdatetime) {
        if (this.navalLayer) this.navalLayer.remove();
        // const url = "https://nogotiles.bgeo.fi:6011/" + type + "/{z}/{x}/{y}?t=" + dayjs(this.routesStore.routeSettings.startdate + " " + this.routesStore.routeSettings.starttime).unix();
        const url = "https://nogotiles.bgeo.fi:6011/" + type + "/{z}/{x}/{y}?t=" + unixdatetime;
        this.map.setMinZoom(4)
        this.navalLayer = L.tileLayer(url, {
            minZoom: 4,
            maxZoom: 20,
        }).addTo(this.map)
    }

    showDepths = (coordinates) => {
        myAxios.get(`api/v1/route/depth_info?lat=${coordinates.lat}&lon=${coordinates.lng}&radius=5`)
            .then(resp => {
                this.depthsList.clearLayers()
                let geoJsonLayer = L.geoJson(resp.data, {
                    style: function (feature) {
                        return feature.properties && feature.properties.style;
                    },
                    onEachFeature: function (f, l) {
                        delete f.properties.style
                        l.bindPopup('<pre>' + JSON.stringify(f.properties, null, ' ').replace(/[\{\}"]/g, '') + '</pre>');
                    }
                }).addTo(this.depthsList)
            });
    }

    clearInfoObjects() {
        objectsPolyline.clearLayers()
        pointsLayer.clearLayers()
    }

    drawInfoObject(payload) {

        this.clearInfoObjects()

        let lines = payload.lines
        let type = payload.type
        let needZoom = payload.zoom

        if (type === "point") {
            let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
            this.map.addLayer(pointsLayer)
            objectCircle.addTo(pointsLayer);

        }

        objectsPolyline.clearLayers()

        if (type === 'line') {
            let object = new L.Polyline(lines, {
                color: '#5e00ff',
                weight: 3,
                opacity: 1,
                smoothFactor: 1
            });
            object.addTo(objectsPolyline)

        } else {
            let object = new L.Polygon(lines, {
                color: '#5e00ff',
                weight: 3,
                opacity: 1,
                smoothFactor: 1
            });
            object.addTo(objectsPolyline)

        }

        if (needZoom) {
            let allCoordinates = []
            lines.forEach(line => {
                line.forEach(point => {
                    allCoordinates.push([point.lat, point.lon])
                })
            })
            console.log("nnnn", allCoordinates)

            let p
            if (type === 'line') {
                p = L.polyline(allCoordinates)
            } else {
                p = L.polygon(allCoordinates)
            }

            let b = p.getBounds()
            this.map.fitBounds(b)
        }
    }

    drawLogbookState() {
        this.logbookGroup.clearLayers()

        if (this.logbookStore.getLogsCount > 0) {
            let report = this.logbookStore.getLatestLog

            console.log("gggg", report)

            let iconImg = require("../../assets/ship_1tx_green.png")
            let icon = L.icon({
                iconUrl: iconImg,
                iconSize: [20, 40],
                iconAnchor: [10, 20],
            });

            let text = dayjs(report.time).format("YYYY-MM-DD HH:mm:ss") + '<br>'
            let positionMarker = new L.marker([report.pos.lat, report.pos.lon], {
                icon: icon,
                rotationAngle: report.hdg
            });

            positionMarker.bindTooltip(text, {permanent: true, direction: 'top', offset: L.point(0, -17)})
            positionMarker.addTo(this.logbookGroup);
        }
    }

    restoreRoute() {
        if (this.routesStore.getWaypointsCount > 0) {
            this.redrawRoute()
            // this.refreshControls()
            // this.restoreSelectedWaypointIndex()
        }
    }

    boundRoute() {

        let wp1 = this.routesStore.getWaypoints[0]
        let wp2 = this.routesStore.getWaypoints[this.routesStore.getWaypoints.length - 1]
        console.log("aaaa", wp1)
        console.log("aaaa", wp2)


        this.map.fitBounds([
            [wp1.lat, wp1.lon],
            [wp2.lat, wp2.lon]
        ]);

        // this.routesStore.getWaypoints.forEach(waypoint => {
        //     waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon))
        // })
        //
        // let bounds = routeLine.getBounds()
        // this.map.fitBounds(bounds);
    }

    toggleNoGoMode(state) {
        // this.nogoZoneGroup.clearLayers()

        if (state) {
            this.map.removeControl(this.drawControl)
            this.isDrawNoGoZonesStarted = false
        } else {
            this.drawControl.addTo(this.map);
            this.isDrawNoGoZonesStarted = true

        }
    }

    saveNoGoZones() {
        let resctrictedZones = [];
        this.nogoList.getLayers().forEach(area => {
            let bounds = area.getBounds()
            let restZone = {
                "south": bounds.getSouth(),
                "north": bounds.getNorth(),
                "west": bounds.getWest(),
                "east": bounds.getEast()
            }
            resctrictedZones.push(restZone)
        })
        eventBus.$emit('save-nogo-zones', resctrictedZones)
    }
}