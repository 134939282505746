import 'leaflet'
import store from "@/store";
const DEFAULT_CELL_COLOR = 'black'
const ACTIVE_CELL_COLOR = 'lightgreen'
const SELECTED_CELL_COLOR = '#ff9001'

const ACTIVE_STYLE = {color: ACTIVE_CELL_COLOR, fillOpacity: 0.5}
const DEFAULT_STYLE = {color: DEFAULT_CELL_COLOR, fillOpacity: 0.05, weight: 1, opacity: 1};
const SELECTED_STYLE = {color: SELECTED_CELL_COLOR, weight: 4, opacity: 1, fillOpacity: 0.2}
const defaultOptions = {cell : undefined, id: undefined, isSelected : undefined, cellObj: undefined , nativeBand: undefined, disabled: undefined}
let activeCell = defaultOptions
let tmpCell = defaultOptions
let activePane
let chartsLayers
let chartsData

const init = (pane, chartLayers, chartData) =>{
    getLayers(chartLayers, chartData);
    setActivePane(pane);
}
const getLayers = (chartLayers, chartData) => {
  chartsLayers = chartLayers;
  chartsData = chartData;
}
const removeFromActivePane = (cell) => {
    cell.cellObj.removeFrom(activePane)
    cell.cellObj.addTo(activeCell.isSelected ? chartsData.selectedCells.lg : chartsLayers[activeCell.nativeBand]);
}
const addToActivePane = (cell) => {
    cell.cellObj.removeFrom(chartsLayers[activeCell.nativeBand])
    cell.cellObj.addTo(activePane);
}
const changeActiveCell = (cell, id, isSelected, cellObj, nativeBand, disabled) =>{

    if (activeCell.id) {

        activeCell.cell.setStyle(!activeCell.disabled && activeCell.isSelected ? SELECTED_STYLE : DEFAULT_STYLE);
        removeFromActivePane(activeCell)
    }
    tmpCell.id = id;
    tmpCell.cell = cell;
    tmpCell.cellObj = cellObj
    tmpCell.isSelected = isSelected
    tmpCell.nativeBand = nativeBand
    tmpCell.disabled = disabled
    // tmpCell.cell.bringToFront();
    console.log(tmpCell.id, activeCell.id, 'cc')
    Object.assign(activeCell, tmpCell);
    tmpCell = defaultOptions
    console.log(tmpCell.id, activeCell.id, 'cc', '2')
    addToActivePane(activeCell)
    activeCell.cell.setStyle(ACTIVE_STYLE);

}
const syncSelectedStatus = (id, sts, cell, disabled) => {
    console.log(id, activeCell.id, 'otk')
    if (id === activeCell.id) {
        activeCell.isSelected = sts
        activeCell.disabled = disabled
        if (disabled){
            activeCell.cell.setStyle(DEFAULT_STYLE)
        }
        else{
            activeCell.cell.setStyle(ACTIVE_STYLE)
        }
        addToActivePane(activeCell)
    }
    else if (!disabled ){
        console.log(disabled, id, 'otk')
        cell.setStyle(SELECTED_STYLE)
    }
    else if (disabled){
        console.log(disabled, id, 'otk')

        cell.setStyle(DEFAULT_STYLE)
    }

}
const setActivePane = (pane) => {
    activePane = pane;
}
export {DEFAULT_CELL_COLOR,
    SELECTED_CELL_COLOR,
    ACTIVE_CELL_COLOR,
    changeActiveCell,
    syncSelectedStatus,
    init
}