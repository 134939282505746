import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "@/store";
import dayjs from "dayjs";

let routesStore, momentaryStore
let prevPublications = '--'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function createVoyagePlan() {
    routesStore = useRoutesStore()
    momentaryStore = useMomentaryStore()
    let rows = []

    let columns = [];
    columns.push({text: '#', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Lat/Lon', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Planned\nspeed', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'ETA', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Bearing', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Trip', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Minimum\nUKC', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Charts', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Publications', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Comments', style: 'tableHeader', alignment: 'center'});

    rows.push(columns)

    routesStore.routeResponse.routePoints.forEach((item, i) => {
        let values = []
        values.push({text: i, style: 'tableText'});
        values.push({text: getLatLon(item), style: 'tableText'});
        values.push({text: getSpeed(i), style: 'tableText'});
        values.push({text: getETA(i), style: 'tableText'});
        values.push({text: getBearing(i), style: 'tableText'});
        values.push({text: getTrip(i), style: 'tableText'});
        values.push({text: '', style: 'tableText'});
        values.push({text: getCharts(i), style: 'tableText'});
        values.push({text: getPublications(i), style: 'tableText'});
        values.push({text: getDangers(i), style: 'tableText'});
        rows.push(values)
    })

    let dd = {
        pageOrientation: 'landscape',
        content: [
            {
                table: {
                    widths: ['*', '*', '*'],
                    headerRows: 1,
                    body: [
                        [{text: 'PASSAGE PLAN', style: 'tableHeader', colSpan: 3, alignment: 'center'}, {}, {}],
                        ['Date: ' + getStartDate(), {text: 'Route Name:', colSpan: 2}, {}],
                        ['Total distance: ' + getTotalDistance(), {rowSpan: 4, text: 'Additional Notes:'}, '' ],
                        // ['Tide PILOTAGE Port:', {rowSpan: 2, text: 'Additional Notes:'}, 'Total distance: ' + getTotalDistance() ],
                        [' ', ' ', ' '],
                        [' ', ' ', ' '],
                        [' ', ' ', ' '],

                    ]
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 60, 'auto', 'auto', 110, '*'],
                    body: rows
                }
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black'
            },
            tableText: {
                fontSize: 11,
                color: 'gray'
            }
        },
        defaultStyle: {
            // alignment: 'justify'
        }
    }

    pdfMake.createPdf(dd).download("passage-plan.pdf", {});

}

function getStartDate() {
    const date = routesStore.routeSettings.startdate
    return date
}

function getTotalDistance() {
    const distance = getTrip(routesStore.routeResponse.routePoints.length - 1)
    return distance
}

function getLatLon(item) {
    const latlon = item.lat.toFixed(6) + "\n" + item.lon.toFixed(6)
    return latlon
}

function getSpeed(index) {
    const speed = routesStore.getSpeed
    return speed + ' kn'
}

function getBearing(index) {
    const radians = routesStore.routeResponse.routePoints[index].info.course
    let degrees = radians * (180 / Math.PI)
    // const val = +degrees.toFixed(1);
    degrees = degrees.toFixed(1);
    return degrees + 'º'
}

function getTrip(index) {
    let units
    let tripFromStart = routesStore.routeResponse.routePoints[index].info?.fromStartMeters ?? 0
    if (tripFromStart >= 1000) {
        tripFromStart *= 0.000539957
        units = ' nm'
        tripFromStart = tripFromStart.toFixed(1)
    } else {
        units = ' m'
        tripFromStart = tripFromStart.toFixed(0)
    }
    return tripFromStart + units
}

function getETA(index) {
    const secondsFromStart = routesStore.routeResponse.routePoints[index].info?.fromStartSeconds ?? 0
    const startTime = dayjs(routesStore.routeSettings.startdate + " " + routesStore.routeSettings.starttime)
    let ETA = startTime.add(secondsFromStart, "seconds").format("DD/MM HH:mm")
    return ETA
}

function getCharts(index) {
    let chartsList = ""
    if (index + 1 in momentaryStore.route.chartsbylegs) {
        const charts = momentaryStore.route.chartsbylegs[index + 1]
        charts.forEach(item => {
            chartsList += item + "\n"
        })
    }
    return chartsList
}

function getPublications(index) {
    let pubsList = ""
    if (index + 1 in momentaryStore.route.pubsbylegs) {
        const pubs = momentaryStore.route.pubsbylegs[index + 1]
        pubs.forEach(item => {
            pubsList += item + "\n"
        })
    }

    if (prevPublications === pubsList)
        pubsList = '(the same as above)'
    else
        prevPublications = pubsList

    return pubsList
}

function getDangers(index) {

    // TODO
    return []

    const allDangers = routesStore.routeResponse.dangerObjects
    const legDangers = allDangers.filter(function (e) {
        return e.legIndex === index + 1
    })

    // eslint-disable-next-line no-debugger
    // debugger
    let dangersNames = 'Dangers: '
    let dangerObjects = new Set()
    legDangers.forEach((item) => {
        dangerObjects.add(item.name)
    })

    dangerObjects.forEach((item) => {
        dangersNames += item + ", "
    })
    dangersNames = dangersNames.replace(/,\s$/, "");
    dangersNames = dangersNames.replace(/Dangers:\s$/, "");

    return dangersNames
}
