<template>
  <div>
    <b-modal v-model="modal_warning" title="Warning" ok-only>
      <p>
        The order is outdated
      </p>
    </b-modal>

    <items :data=current.data></items>
    <delivery :data=current.data></delivery>
    <updates :data=current.data></updates>

    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col>
            <b-form-select v-model="$parent.$parent.selected_ship" :options="$parent.$parent.ships"
                           size="sm"></b-form-select>
          </b-col>
          <b-col class="text-left">
            <b-button variant="info" size="sm" @click="checkUpdates()">Check updates</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-overlay :show="show_spinner" rounded="sm" spinner-variant="danger">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>&nbsp;{{ $store.state.fickle.requests.current_request }}</strong>
        </div>
      </template>

      <b-table v-if="!show_spinner"
               ref="orderstable1"
               class="secondary"
               :stacked="is_mobile"
               :items="visibleRows()"
               :fields="visible_fields"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :busy="is_busy"
               select-mode="single"
               selectable
               show-empty
               responsive
               sort-icon-left
               small
               hover
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>&nbsp;{{ $store.state.fickle.requests.current_request }}</strong>
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ utils.formatDate(data.value).datetime }}
        </template>

        <template #cell(userId)="row">
          <div v-if="row.item.statusChangeLog && row.item.statusChangeLog.length > 0">
            {{ row.item.statusChangeLog[0].userEmail }}
          </div>
        </template>

        <template #cell(provider)="row">
          <b-link @click="showItems(row.item)" class="smt17">{{ row.item.productType }}</b-link>
        </template>

        <template #cell(lastDeliveryDate)="row">
          <span v-if="row.item.status === 'complete'">
            <b-link @click="showDelivery(row.item)" class="smt17">{{
                utils.formatDate(row.item.updatedAt).date
              }}</b-link>
          </span>
        </template>

        <template #cell(device.shipName)="row">
          <span v-b-tooltip.hover :title="row.item.device ? row.item.device.deviceId : ''">{{
              shipName(row.item.device).type
            }} <br> {{ shipName(row.item.device).val }}</span>
        </template>

        <template #cell(paymentMethod.paymentMethod)="row">
          <span
              v-if="row.item.paymentMethod?.paymentMethod === 'agreement'">agreement<br>{{ row.item.paymentMethod.agreementInfo.title }}</span>
          <span v-else>{{ row.item.paymentMethod?.paymentMethod }}</span>
        </template>

        <template #cell(load)="row">
          <b-button-group>
            <b-button
                v-if="$store.state.auth.user.role === 'distributor' && row.item.status !== 'complete' && row.item.device &&  row.item.device.appTypeId && (row.item.device.appTypeId === 11 || row.item.device.appTypeId === 10) && row.item.paymentMethod.paymentMethod === 'agreement'"
                size="sm" variant="info" @click="loadOrder(row.item)" class="mr-1">Edit
            </b-button>
            <b-dropdown size="sm"
                        v-if="$store.state.auth.user.role === 'distributor' && row.item.status !== 'complete' && row.item.device &&  row.item.device.appTypeId && (row.item.device.appTypeId === 11 || row.item.device.appTypeId === 10) && row.item.paymentMethod.paymentMethod === 'agreement'"
                        variant="info" text="Status">
              <b-dropdown-item @click="approveOrder(row.item, 'pre_order')">Request</b-dropdown-item>
              <b-dropdown-item @click="approveOrder(row.item, 'owner_approved')">To pay</b-dropdown-item>
              <b-dropdown-item @click="approveOrder(row.item, 'cancelled_by_distirbutor')">Cancel</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </template>

        <template #cell(status)="row">
          <h6>
            <b-badge :variant=getStatus(row.item.status).style>{{ getStatus(row.item.status).name }}</b-badge>
          </h6>
        </template>

        <template #cell(displayId)="row">
          <span v-b-tooltip.hover :title=row.item.orderId>{{ row.item.displayId }}</span>
        </template>

        <template #cell(updatedAt)="row">
          <b-link @click="showUpdates(row.item)" class="smt17">{{ utils.formatDate(row.item.updatedAt).date }}</b-link>
        </template>

        <template #cell(price.price)="row">
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === 'RUB'">
            {{ row.item.price.price }} ₽
          </div>
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === 'EUR'">
            {{ row.item.price.price }} €
          </div>
          <div v-if="row.item.price && row.item.price.currency && row.item.price.currency === 'USD'">
            ${{ row.item.price.price }}
          </div>
          <div
              v-if="row.item.price && row.item.price.currency && row.item.price.currency === '' && row.item.paymentMethod.paymentMethod === 'stripe'">
            {{ row.item.price.price }} €
          </div>
          <div
              v-if="row.item.price && row.item.price.currency && row.item.price.currency === '' && row.item.paymentMethod.paymentMethod === 'yoomoney'">
            {{ row.item.price.price }} ₽
          </div>
        </template>
      </b-table>

    </b-overlay>

  </div>
</template>

<script>
import store from "@/store";
import {formatDate, getAppName} from "@/utils/utils";
import Items from "@/views/transactions/sidebars/Items.vue";
import Delivery from "@/views/transactions/sidebars/Delivery.vue";
import Updates from "@/views/transactions/sidebars/Updates.vue"
import {eventBus} from "@/main";
import router from "@/router";
import * as utils from "../../utils/utils";
import QRWindow from "@/components/qr/QRWindow.vue";
import {changeOrderStatus} from "@/helpers/offline";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {useMomentaryStore} from "@/stores/momentary";
import {useDeviceStore} from "@/stores/device";

export default {
  name: "Transactions",
  components: {Updates, Delivery, Items},
  data() {
    return {
      windowWidth: window.innerWidth,
      modal_warning: false,
      show_spinner: false,
      qrcode: null,
      source1: 'report.pdf',
      sortBy: 'updatedAt',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      current: {
        status_id: '',
        data: null
      },
      ship: {}
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    eventBus.$off('refresh-orders-table')
    eventBus.$on('refresh-orders-table', () => {
      this.$refs.orderstable1.refresh();
    })

    eventBus.$off('delayed-request-order-status')
    eventBus.$on('delayed-request-order-status', () => {
      store.dispatch('transactions/getTransactions').then(() => {
      })
    })
  },
  computed: {
    ...mapStores(useDeviceStore),
    is_mobile() {
      return this.windowWidth <= 760
    },
    is_busy() {
      return this.$store.state.transactions.transactions.length > 0 && this.$store.state.transactions.transactions[0] === "-1" &&
          this.$store.state.charts.s63.products.all.length > 0 && this.$store.state.proto.installationsECDIS.length > 0
    },
    utils() {
      return utils
    },
    visible_fields() {
      return [
        {
          key: 'displayId',
          label: 'ID',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'userId',
          label: 'Customer',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'device.shipName',
          label: 'Ship',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'provider',
          label: 'Content',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'paymentMethod.paymentMethod',
          label: 'Payment',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'price.price',
          label: 'Price',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'updatedAt',
          label: 'Updated',
          sortable: true,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {
          key: 'lastDeliveryDate',
          label: 'Deliveries',
          sortable: false,
          sortDirection: 'desc',
          tdClass: 'small-black',
          thClass: 'small-gray'
        },
        {key: 'load', label: '', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    selectShipByDeviceId(deviceId) {
      return this.ships().find(x => x.appState.deviceId == deviceId)
    },

    getStatus(status) {
      let val = {}
      switch (status) {
        case 'pre_order':
          val = {style: "info", name: "request"}
          break
        case 'owner_approved':
          val = {style: "warning", name: "to pay"}
          break
        case 'new':
          val = {style: "warning", name: "to pay"}
          break
        case 'paid':
        case 'ready_to_publish':
        case 'awaiting_charts"':
        case 'adding_charts"':
          val = {style: "secondary", name: "processing"}
          break
        case 'complete':
          val = {style: "success", name: "complete"}
          break
        case 'error':
          val = {style: "danger", name: "error"}
          break
        case 'cancelled_by_distirbutor':
          val = {style: "danger", name: "cancelled"}
          break
        default:
          val = {style: "light", name: status}
      }

      return val
    },

    ships() {
      let listWithAppstate = this.$store.state.proto.installationsECDIS;
      return listWithAppstate;
    },
    approveOrder(orderItem, status) {

      this.show_spinner = true
      changeOrderStatus(orderItem.orderId, status).then(resp => {
        this.show_spinner = false
        if (resp)
          store.dispatch('transactions/getTransactions').then(() => {
          })
      })
    },

    loadOrder(orderItem) {
      let orderId = orderItem.orderId
      let orderDate = orderItem.createdAt
      let name = orderItem.device.shipName
      let deviceId = orderItem.device.deviceId
      let appId = orderItem.device.appTypeId
      let status = orderItem.status

      console.log(orderId, orderDate, name, deviceId, appId)


      this.show_spinner = true


      let getShip = this.selectShipByDeviceId(deviceId)
      let ship = {
        deviceId: deviceId,
        order_number: orderId,
        order_date: orderDate,
        ecdis_name: name,
        ecdis_imo: "",
        applicationId: appId,
        appState: {
          shipInfo: {
            name: name,
            imo: ""
          }
        }
      }

      console.log(ship)
      if (ship === undefined) {

        this.modal_warning = true
        this.show_spinner = false
        return
      }

      this.onRowSelected([ship])


      this.ship.order_date = new Date(orderItem.createdAt).toLocaleDateString()

      this.onDone()

      this.$store.dispatch('getLatestOrder', {
        order_id: orderId
      }).then(() => {

        store.dispatch('current/clearCart').then(() => {

          let items = this.$store.state.charts.s63.latest_order.data.items
          let idlist = []
          items.forEach(item => {
            idlist.push(item.id)
          })
          this.$store.dispatch('proto/getAgreementsV2').then(() => {
            store.dispatch('addS63Items', idlist).then(() => {
              this.$store.commit('proto/setRestoredOrder', this.$store.state.proto.order)
              router.push("charts")
            })
          })


          this.$store.dispatch('proto/updateOrder', {
            update: true,
            order_status: utils.getStatusByType(status)
          })

        })
      })


    },
    onDone(from) {

      this.$store.dispatch('proto/updateOrder',
          {
            device_id: this.ship.device_id,
            order_number: this.ship.order_number,
            order_date: this.ship.order_date,
            ecdis_name: this.ship.ecdis_name,
            ecdis_imo: this.ship.ecdis_imo,
            application_id: this.ship.application_id
          }).then(() => {
      })

      this.$store.commit("proto/setSelectShip", true)
      this.$store.dispatch('proto/updateOrder', {step: "creating"})

    },
    onRowSelected(items) {


      if (items.length > 0) {
        this.ship.order_number = items[0].order_number
        this.ship.order_date = items[0].order_date
        this.ship.ecdis_name = items[0].appState.shipInfo.name
        this.ship.ecdis_imo = items[0].appState.shipInfo.imo
        this.ship.device_id = items[0].deviceId
        this.ship.application_id = items[0].applicationId
      } else {
        this.ship.order_number = null
        this.ship.order_date = null
        this.ship.ecdis_name = null
        this.ship.ecdis_imo = null
        this.ship.device_id = null
        this.ship.application_id = null
      }

    },
    shipName(device) {
      let val = 'unknown'
      let type = 'unknown'
      if (device !== null) {
        type = getAppName(device ? device.appTypeId : -1)
        val = device?.shipName ?? ''
      }
      return {"type": type, "val": val}
    },
    showItems(data) {
      this.current.data = data;
      this.$store.dispatch('fickle/getOrder', data.orderId)
      this.$root.$emit('bv::toggle::collapse', 'items-sidebar');
    },
    showDelivery(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'delivery-sidebar');
    },
    showUpdates(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'updates-sidebar');
    },
    visibleRows() {
      let orders = []
      let deviceID = this.deviceStore.getDeviceID
      if (deviceID) {
        orders = this.$store.state.transactions.transactions.filter(function (e) {
          return e["device"]["deviceId"] === deviceID;
        });
      }
      return orders;
    },
    checkUpdates() {

    }
  },
}
</script>

<style>
.position-absolute {
  position: fixed !important;
}
</style>


<style scoped>
.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>