<template>
  <div>
    <b-container>

<!--      <h6 align="center" class="mt-2">REPORT</h6>-->
      <div>
        <b-row>
          <b-col>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                      @click="closeReport">
              <!--                        @click="clearPorts();displayContent = 'showRoute'">-->
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>

          </b-col>
        </b-row>
      </div>

      <file-pond-input ref="logbook-images"></file-pond-input>

      <b-form size="sm" @submit="addReport()">
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="3">
                <label>LAT:</label>
              </b-col>
              <b-col>
                <b-form-input size="sm" id="input-1" v-model="form.lat" :state="latState" type="text" placeholder=""
                              required></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col cols="3">
                <label>LON:</label>
              </b-col>
              <b-col>
                <b-form-input size="sm" id="input-1" v-model="form.lon" :state="lonState" type="text" placeholder=""
                              required></b-form-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row size="sm">
          <b-col>
            <b-row>
              <b-col cols="3">
                <label>HDG:</label>
              </b-col>
              <b-col>
                <b-form-spinbutton size="sm" type="range" min="0" step="10" max="350" v-model="form.hdg"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col cols="3">
                <label>SOG:</label>
              </b-col>
              <b-col>
                <b-form-spinbutton size="sm" type="range" min="0" step="1" max="50" v-model="form.sog"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

<!--        <b-row>-->
<!--          <b-col>-->
<!--            <b-row>-->
<!--              <b-col cols="3">-->
<!--                <label>COG:</label>-->
<!--              </b-col>-->
<!--              <b-col>-->
<!--                <b-form-input size="sm" id="input-1" v-model="form.cog" type="text"-->
<!--                              placeholder="optional"></b-form-input>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-row>-->
<!--              <b-col cols="3">-->
<!--                <label>ROT:</label>-->
<!--              </b-col>-->
<!--              <b-col>-->
<!--                <b-form-input size="sm" id="input-1" v-model="form.rot" type="text"-->
<!--                              placeholder="optional"></b-form-input>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-col>-->
<!--        </b-row>-->

        <b-row>
          <b-col>
            <b-row>
              <b-col cols="3">
                <label>MESSAGE:</label>
              </b-col>
              <b-col>
                <b-form-textarea
                    id="textarea"
                    v-model="form.message"
                    placeholder=""
                    rows="3"
                    max-rows="3"
                    :state="messageState"
                    required
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!--        <label>MESSAGE:</label>-->

        <!--        <b-form-textarea-->
        <!--            id="textarea"-->
        <!--            v-model="text"-->
        <!--            placeholder="Enter something..."-->
        <!--            rows="3"-->
        <!--            max-rows="6"-->
        <!--        ></b-form-textarea>-->
        <!--        -->
        <!--          <b-form-input size="sm" id="input-1" v-model="form.message" :state="messageState" type="text" placeholder=""-->
        <!--                        required></b-form-input>-->
        <div>

          <b-button-group>

            <b-button size="sm" variant="info" class="mr-1" v-if="!audio.is_recording" @click="recordAudio">
              <b-icon icon="mic"></b-icon>
            </b-button>
            <b-button size="sm" variant="danger" class="mr-1" v-else @click="stopAudio">
              <b-icon icon="mic-mute"></b-icon>
            </b-button>

            <!--              <b-button size="sm" variant="warning" @click="addAudio()" class="mr-1">Add audio</b-button>-->
            <!--            <b-button size="sm" variant="info" @click="addPhotos()" class="mr-1">-->
            <!--              <b-icon icon="camera"></b-icon>-->
            <!--            </b-button>-->
          </b-button-group>

          <b-button size="sm" :disabled="(audio.is_recording || !lonState || !latState || !messageState)" variant="info" @click="saveReport()">Save</b-button>


          <!--          <b-button size="sm" type="button" @click="addReport()" :disabled="!(latState && lonState)" variant="info">Save & Attach photo</b-button>&nbsp;-->
        </div>
        <br>
      </b-form>
    </b-container>

  </div>
</template>

<script>
import FilePondInput from "@/components/FilePondInput";
import store from "@/store";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {eventBus, logbooks_map} from "@/main";
import dayjs from "dayjs";
// import qrcode from "@/data/ship2.json";

export default {
  name: "LogbookReport",
  components: {FilePondInput},
  data() {
    return {
      windowWidth: window.innerWidth,

      audio: {
        is_recording: false,
        recorder: null,
        chunks: [],
        device: null,
        blobObj: null
      },

      audio_blob: null,
      audio_src: null,

      audioInput: false,
      photoInput: false,
      form: {
        lat: '',
        lon: '',
        hdg: 0,
        sog: 10,
        message: '',
      }
    }
  },
  mounted() {

    eventBus.$off('set-logbook-coordinates')
    eventBus.$on('set-logbook-coordinates', coordinates => {
      this.form.lat = String(coordinates.lat.toFixed(6))
      this.form.lon = String(coordinates.lng.toFixed(6))
    })



    // this.$root.$off('bv::toggle::collapse')
    // this.$root.$on('bv::toggle::collapse', (bvEvent, modalId) => {
    // if (this.is_mobile)
    //   navigator.geolocation.getCurrentPosition(success, error);
    // this.$refs['manlog-sidebar'].show();
    // this.$refs['manlog-sidebar'].toggle()
    // this.$root.$emit('bv::toggle::collapse', 'manlog-sidebar')
    // })

    // this.$root.$on('bv::sidebar::show', (bvEvent, modalId) => {
    //   alert(2)
    //   console.log('Modal is about to be shown', bvEvent, modalId)
    // })

    if (this.is_mobile)
      navigator.geolocation.getCurrentPosition(success, error);

    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    const success = (position) => {
      this.form.lat = String(position.coords.latitude);
      this.form.lon = String(position.coords.longitude);

      // console.log("pos ", latitude, longitude)
    };

    const error = (err) => {
      console.log(error)
    };


  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    latState() {
      return this.form.lat.length > 0
    },
    lonState() {
      return this.form.lon.length > 0
    },
    messageState() {
      return this.form.message.length > 0
    },
    ...mapStores(useLogbookStore),
  },
  methods: {
    closeReport() {
      this.clearForm()
      this.$parent.$parent.displayContent = 'logbook'
    },
    clearForm() {
      this.form.lat = ''
      this.form.lon = ''
      this.form.sog = 10
      this.form.hdg = 0
      this.form.message = ''
      this.audio_blob = null
      this.audio_src = null
    },
    saveReport() {
      // console.log(this.form.lat)
      // console.log(this.form.lon)
      // console.log(this.$parent.selected_row)
      //
      // console.log(this.$parent.selected_row.primarInfo.primarPermit)
      // console.log(this.$parent.selected_row.primarInfo.shipInfo.name)

      // const nowTime = new Date().toISOString()
      // const a = dayjs()
      let report = {
        time: dayjs(),

        // name: this.$parent.selected_row.primarInfo.shipInfo.name,
        // name: 'AAAA',
        hdg: this.form.hdg,
        sog: this.form.sog,

        message: this.form.message,

        pos: {
          lat: this.form.lat,
          lon: this.form.lon
        },
        // route: {
        //   name: "",
        //   points: []
        // },
        // history: [],
        // permit: '1234',
        images: this.$refs['logbook-images'].getImages(),
        audio: {
          src: this.audio_src,
          blob: this.audio_blob
        }
        // permit: this.$parent.selected_row.primarInfo.primarPermit
      }

      // this.$refs['logbook-images'].aaaa()

      logbooks_map.drawAllReportPoints(report)
      // logbooks_map.drawReportPoint(form, time: dayjs(a).format("YYYY-MM-DD HH:mm:ss")})

      this.logbookStore.addReport(report)
      this.clearForm()
      this.$parent.$parent.displayContent = 'logbook'


      // store.commit("fickle/setQRInfo", report)

      // this.photoInput = true
      // this.$refs['manlog-sidebar'].hide()
    },
    addPhotos() {
      this.photoInput = !this.photoInput;
      // this.$refs['logbook-sidebar'].hide()
    },
    cancelPhotos() {
      this.photoInput = !this.photoInput;
      // this.$refs['logbook-sidebar'].hide()
    },
    recordAudio() {
      if (!this.audio.device)
        this.audio.device = navigator.mediaDevices.getUserMedia({audio: true});
      this.audio.is_recording = true
      this.audio.device.then((stream) => {
        this.audio.recorder = new MediaRecorder(stream);
        this.audio.recorder.ondataavailable = (e) => {
          this.audio.chunks.push(e.data);
          if (this.audio.recorder.state === "inactive") {
            let blob = new Blob(this.audio.chunks, {type: "audio/wav"});
            // save to blobObj
            this.audio.blobObj = blob;
            this.audio.chunks = [];
            // emit to parent
            console.log("finished")
            const audioURL = window.URL.createObjectURL(this.audio.blobObj);
            console.log(audioURL)

            this.audio_src = audioURL
            this.audio_blob = this.audio.blobObj

            // store.commit("fickle/setQRAudio", {blob: this.audio.blobObj, url: audioURL})

            this.audio.blobObj = null;
          }
        };
        // start
        this.audio.recorder.start();
      });
    },
    stopAudio() {
      // stop
      this.audio.is_recording = false
      this.audio.recorder.stop();

      console.log("llll", this.audio)
    },
    changeShip() {
      logbooks_map.restoreReports()
    }

  }


}
</script>

<style scoped>

</style>