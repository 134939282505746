import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import store from "@/store";
import dayjs from "dayjs";

export function getDepth(coordinates) {
    let URL = 'api/v1/depth?lat=' + coordinates.lat + '&lon=' + coordinates.lng

    myAxios.get(URL).then(resp => {
        L.popup().setLatLng(coordinates).setContent(resp.data + ' m').openOn(this.map);
    })
}
export function getWeather(coordinates, time) {
    // let time = store.state.fickle.weather.datetime
    //TODO
    // let time = dayjs().unix()
    let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + coordinates.lat + '&lon=' + coordinates.lng + '&t=' + time

    myAxios.get(URL).then(resp => {
        let temperature = ''
        let wind = ''
        if (!resp.data.temperature.toString().startsWith('1.7976931348'))
            temperature = 'Temperature: ' + resp.data.temperature + '° C'

        if (!resp.data.wind.toString().startsWith('1.7976931348'))
            wind = 'Wind: ' + resp.data.wind + ' m/s (' + resp.data.windAngle + '°)'

        let content = 'Date: ' + dayjs.unix(time).format('DD-MMM HH:mm') + '<br>'
        content += temperature ? temperature  + '<br>': ''
        if (wind)
            content += wind

        L.popup().setLatLng(coordinates).setContent(content).openOn(this.map);
    }).catch(error => {
        let content = 'Date: ' + dayjs.unix(time).format('DD-MMM HH:mm') + '<br>'
        content += 'Weather is not available'
        L.popup().setLatLng(coordinates).setContent(content).openOn(this.map);
    })
}

export function showInfo(coordinates) {
    let URL = 'api/v1/objectsinfo?lat=' + coordinates.lat + '&lon=' + coordinates.lng

    // store.commit("fickle/setObjectInfoLoading", true)
    myAxios.get(URL).then(resp => {
        // console.log(resp.data)
        // store.commit("fickle/setObjectInfoLoading", false)
        eventBus.$emit('show-map-info', {info: resp.data, coordinates: coordinates})
    })
}

export function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2 - lon1);
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d;
}

