<template>
  <div>
    <b-modal v-model="is_route_error" title="Route calculating error" ok-only>
      <div v-html="route_error_text"/>
    </b-modal>

    <b-modal v-model="is_show_map_info" title="Map info" scrollable modal-class="myclass" draggable="false" ok-only>
      <div v-html="map_info_text"/>
    </b-modal>

    <b-modal visible v-model="is_show_bypass_dialog" header-class="justify-content-center" title="Select bypass method" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
      <div class="col-12 text-center">
        <b-button size="sm" variant="primary" @click="okBypass('detailed')">Detailed</b-button>
        <b-button size="sm" variant="primary" @click="okBypass('general')" class="ml-1">General</b-button>
        <b-button size="sm" variant="primary" @click="okBypass('filtered')" class="ml-1">Filtered</b-button>
        <b-button size="sm" variant="primary" @click="okBypass('connect')" class="ml-1">Connect</b-button>
        <b-button size="sm" variant="danger" @click="cancelBypass" class="ml-1">Cancel</b-button>
      </div>
    </b-modal>

    <h6 align="center" class="mt-2">ROUTES</h6>

    <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>
<!--    <b-overlay :show="$store.state.routes.progress.isCalculating" rounded="sm">-->
<!--      <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>-->

<!--      <template #overlay>-->
<!--        <div class="text-center">-->
<!--          <p id="cancel-label">Route/Safety calculating, please wait...</p>-->

<!--          <b-progress class="mt-2" :max=100 show-value show-progress animated>-->
<!--            <b-progress-bar :value="$store.state.routes.progress.calculatingProgress"-->
<!--                            :label="`${($store.state.routes.progress.calculatingProgress)}%`"-->
<!--                            variant="primary"></b-progress-bar>-->
<!--          </b-progress>-->

<!--          <b-button class="mt-2" size="sm" @click="cancelRoute()" variant="danger">Cancel</b-button>-->
<!--        </div>-->
<!--      </template>-->
<!--    </b-overlay>-->
  </div>
</template>

<script>
import {eventBus, routes_map} from "@/main";

export default {
  props: ["map", "height"],
  data() {
    return {
      is_route_error: false,
      is_show_map_info: false,
      is_show_bypass_dialog: false,
      route_error_text: '',
      map_info_text: ''
    }
  },

  mounted() {
    this.map.init(this.$refs.mm);

    eventBus.$off('show-route-error')
    eventBus.$on('show-route-error', (text) => {
      this.route_error_text = text
      this.is_route_error = true
    })

    eventBus.$off('showCalculateByPassDialog')
    eventBus.$on('showCalculateByPassDialog', () => {
      this.is_show_bypass_dialog = true
    })

  },
  beforeDestroy() {
    eventBus.$emit('cancel-auto-route')
  },
  methods: {
    cancelBypass() {
      this.is_show_bypass_dialog = false
      routes_map.clearBypass()
    },

    okBypass(type) {
      this.is_show_bypass_dialog = false
      routes_map.startBypassCalculation(type)
    }

    // cancelRoute() {
    //   eventBus.$emit('cancel-auto-route')
    // }
  }
}
</script>

<style scoped>
#mymap {
  z-index: 1;
}

/deep/ .my-class {
  background: black;
  color: white;
}

/deep/ .myclass > div {
  position: absolute !important;
  top: -10px !important;
  left: 10px !important;
  background-color: #77ccdd !important;
}

/deep/ .myclass > .modal-dialog > .modal-content {
  background-color: #77ccdd !important;
}

.leaflet-grab {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab {
  cursor: move;
}
</style>
