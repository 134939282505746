import L from "leaflet";
import {eventBus} from "@/main";

export function createMoveControl() {

    return L.Control.Button = L.Control.extend({
        options: {
            position: 'topright'
        },
        onAdd: (map) => {
            let container = L.DomUtil.create('div', 'button-19');

            let button = L.DomUtil.create('input', 'button-18', container);
            button.value = "←";
            button.type = "button";
            button.title = "move point left"
            L.DomEvent.disableClickPropagation(button);

            L.DomEvent.on(button, 'click',  () => {
                this.moveWaypoint(270);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "↑";
            button.type = "button";
            button.title = "move point up"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveWaypoint(0);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "↓";
            button.type = "button";
            button.title = "move point down"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveWaypoint(180);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "→";
            button.type = "button";
            button.title = "move point right"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveWaypoint(90);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "↤";
            button.type = "button";
            button.title = "move leg left"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveLeg(270);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "↥";
            button.type = "button";
            button.title = "move leg up"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveLeg(0);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "↧";
            button.type = "button";
            button.title = "move leg down"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveLeg(180);
            });

            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "↦";
            button.type = "button";
            button.title = "move leg right"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.moveLeg(90);
            });


            button = L.DomUtil.create('input', 'button-18', container);
            button.value = "×";
            button.type = "button";
            button.title = "remove point"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click',  () => {
                this.deleteWaypoint();
            });

            return container;
        },
        onRemove: function (map) {
        },
    });
}

export function createWPControl() {

    return L.Control.Button = L.Control.extend({
        options: {
            position: 'topright'
        },
        onAdd: (map) => {
            eventBus.$off('wp-control-select-wp', map);
            eventBus.$on('wp-control-select-wp', (index) => {
                button2.value = index === -1 ? ' ' : "WP" + index
            })

            let container = L.DomUtil.create('div', 'button-17');

            let button1 = L.DomUtil.create('input', 'button-15', container);
            button1.value = "<";
            button1.type = "button";
            button1.title = "previous point"
            L.DomEvent.disableClickPropagation(button1);
            L.DomEvent.on(button1, 'click', () => {
                this.changeSelectedWaypoint('left')
                map.closePopup();
            });

            let button2 = L.DomUtil.create('input', 'button-16', container);
            button2.value = " ";
            button2.type = "button";
            button2.style.display = 'inline'
            L.DomEvent.disableClickPropagation(button2);
            L.DomEvent.on(button2, 'click', () => {
            });

            let button3 = L.DomUtil.create('input', 'button-15', container);
            button3.value = ">";
            button3.type = "button";
            button3.title = "next point"
            L.DomEvent.disableClickPropagation(button3);
            L.DomEvent.on(button3, 'click', () =>{
                this.changeSelectedWaypoint('right')
                map.closePopup();
            });

            // this.onDisplayWPIndex = function (index) {
            //     button2.value = index === -1 ? ' ' : "WP" + index
            // }

            return container;
        },
        onRemove: function(map) {},
    });
}

export function createClearControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.className = "button-14";
            container.title = "Clear whole route and waypoints";
            container.value = "Clear route";

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                this.clearRoute()
            }
            return container;
        },
        onRemove: (map) => {
        }
    })
}

export function createCheckSafetyControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            eventBus.$off('wp-control-toggle-check-safety', map);
            eventBus.$on('wp-control-toggle-check-safety', (isNeedCheck) => {
                if (isNeedCheck) {
                    eventBus.$emit('switch-wp-tab')
                    L.DomUtil.addClass(container, "button-20");
                    this.isCheckSafetyControlFired = true;
                }
                else {
                    L.DomUtil.addClass(container, "button-14");
                    this.isCheckSafetyControlFired = false
                }
            })


            let container = L.DomUtil.create('input');
            container.type = "button";
            container.className = "button-14";
            container.title = "Check the route safety";
            container.value = "Check safety";
            container.style.display = 'inline'

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                this.checkSafety();
            }
            return container;
        },
        onRemove: (map) => {
        }
    })
}

export function createSafetyControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Check the route safety ";
            container.value = "Check route safety";
            container.className = "button-14";

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('check-safety')
            }
            return container;
        }
    })
}

export function createSafetyWPControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Check the point safety ";
            container.value = "Check point safety";
            container.className = "button-14";

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('check-safety')
            }
            return container;
        }
    })
}

export function createRecalculateControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Recalculate safety for the route";
            container.value = "Recalculate route";
            container.className = "button-14"

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('control-recalculate-all')
            }
            return container;
        }
    })
}

export function createRecalculateWPControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Recalculate the point ";
            container.value = "Recalculate point";
            container.className = "button-14";

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('move-wp-recalculate')
            }
            return container;
        }
    })
}

export function createMeasureControl() {
    let measureControl;
    let options = {position:'bottomleft',
        unit:'nauticalmiles',
        showBearings:true,
        clearMeasurementsOnStop: false,
        showClearControl: true,
        showUnitControl: true,
        measureControlLabel: "&#8737",
        distanceShowSameUnit: true,
        measureControlTitleOn: 'Turn on measure tool',
        measureControlTitleOff: 'Turn off measure tool',
    };
    measureControl = L.control.polylineMeasure(options)

    return measureControl
}
export function createWeatherTextBox() {
    let textbox   = L.Control.extend({
        onAdd: function() {

            var text = L.DomUtil.create('div');
            text.id = "weather_text";
            text.innerHTML = ""
            return text;
        },

    });
    return new textbox({ position: 'bottomleft' })
}

export function createMakeNoGoControl() {
    return L.Control.extend({
        options: {
            position: 'topleft'
        },

        onAdd: (map) => {
            eventBus.$off('toggle-mode-after-auto')
            eventBus.$on('toggle-mode-after-auto', () => {
                container.button_state = true
                container.value = "Edit NoGo zones"
            })

            let container = L.DomUtil.create('input');
            container.button_state = true;
            container.type = "button";
            container.title = "Toggle ";
            container.value = "Edit NoGo zones";
            container.className = "button-13"

            container.onclick = (e) => {
                console.log("event ", e)
                e.stopPropagation();
                e.preventDefault();
                container.button_state = !container.button_state;
                map.closePopup();

                this.toggleNoGoMode(container.button_state);

                // eventBus.$emit('toggle-nogo-edit-mode', container.button_state)
                if (container.button_state)
                    this.saveNoGoZones();
                    // eventBus.$emit('save-nogo')

                container.value = container.button_state ? "Edit NoGo zones" : "Exit editing"
            }
            return container;
        }
    });
}

export function createEasyButtons(map) {
    // new L.Control.EasyButton({
    //     position: 'bottomleft',
    //     states: [{
    //         icon: 'bi bi-pencil-square',
    //         title: 'Route by coordinates',
    //         onClick: (btn) => {
    //             eventBus.$emit('route-by-coordinates')
    //         }
    //     }]
    // }).addTo(map)

    // new L.Control.EasyButton({
    //     position: 'bottomleft',
    //     states: [{
    //         icon: 'bi bi-search',
    //         title: 'Search',
    //         onClick: (btn) => {
    //             eventBus.$emit('search-on-map')
    //             eventBus.$emit('show-ports-on-map')
    //         }
    //     }]
    // }).addTo(map)
}