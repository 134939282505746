<template>
  <div >
    <file-pond
      name="ImagesInReports"
      label-idle="Select images..."
      v-bind:allow-multiple="true"
      accepted-file-types="image/jpeg, image/png"
      :allow-image-size-metadata="true"
      v-bind:files="myFiles"
      v-on:init="handleFilePondInit"
      @updatefiles="onUpdateFiles"
      @addfilestart="onProcessFileStart"
      @addfile="onProcessFiles"
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
// import "filepond/dist/filepond.min.css";
import "../components/FilePond.css"

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilepondPluginImageSizeMetadata from '@/plugins/filepond-plugin-image-size-metadata'
import store from "@/store";
// Create component
let FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilepondPluginImageSizeMetadata
);

export default {

  data: function () {
    return {
      myFiles: [],
      images: [],
      processedFiles: [],
    };
  },
  methods: {
    onProcessFileStart() {
      console.log("aaaa1 start")
    },
    onProcessFiles() {
      console.log("aaaa1 process")
    },
    onUpdateFiles(files){

      console.log("aaaa1 update", files)
      this.images = [];
      this.processedFiles = [];
      // console.log(file.getMetadata());
      files.forEach(file => {
        console.log("aaaa1", file.filename)
        // if (!this.processedFiles.includes(file.filename)) {


          // if (file.getFileEncodeDataURL() && !this.processedFiles.includes(file.filename)) {
          if (file.getFileEncodeDataURL()) {
            this.processedFiles.push(file.filename);
            this.images.push({
              src: file.getFileEncodeDataURL(),
              thumbnail: null,
              width: file.getMetadata().size.width,
              height: file.getMetadata().size.height
            })
            console.log("aaaa2", this.images);
          }

        // }

      })
      // store.commit("fickle/setQRImages", this.images)

    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // FilePond instance methods are available on `this.$refs.pond`
    },
    getImages() {
      let val = this.images
      this.images = []
      this.myFiles = []
      this.processedFiles = []
      return val
    }
  },
};
</script>
<style>

</style>